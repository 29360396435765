@import '../../_variables.scss';

.chartContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 370px;
  width: 100%;

  :global(.ui.card) {
    @media (min-width: $sm-max) {
      margin-left: 0;    
    }

    @media (max-width: $sm-max) {
      width: 100%;      
    }
  }

  @media (max-width: $md-max) {
    justify-content: center;  
  }
}

.test {
  color: red;
}
 
.wrapper {
  margin: 16px;

  .fixedWidth {
    width: 250px;
  }
  .fixedWidthFloors {
    width: 150px;
  }
  .widthSize {
    min-width: 300px;
  }
  :global(.ui.table) {
    td.building {
      padding-top: 0;
      padding-bottom: 0;
      height: 48px;
    }
  }
}
.tooltip {
  width: 100%;
  .content {
    display: flex;
    .icon {
      flex-shrink: 0;
    }
  }
}