.button {
  position: absolute;
  outline: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 35px;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer;
  &::before {
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative;
  }

  &.left {
    left: calc(4% + 1px);
    right: auto;
    &::before {
      content: '\e824';
    }
  }

  &.right {
    right: calc(4% + 1px);
    &::before {
      content: '\e825';
    }
  }
}
