@import "src/variables";
@import "src/_mixins";

.wrapper {
  @include light-scrollbar;
}

.dropdown {
  padding: 0 8px 16px 0;
  .dropdownInput {
    padding: 8px;
    line-height: 20px;
    i:global(.caret.down.icon) {
      position: absolute;
      right: 8px;
    }

    // Disabled item styles
    &:global(.ui.selection.dropdown .menu .disabled.item) {
      i:global(.exclamation.circle.icon) {
        position: absolute;
        color: $error;
        font-size: 10px;
        top: 25px;
      }
      :global(.description) {
        margin-left: 15px;
      }
    }
  }
  .large:global(.ui.selection.dropdown),
  .large:global(.ui.selection.dropdown .menu.visible) {
    min-width: 300px; // to override semantic-ui default
    width: 300px;
  }
  .mediumLarge:global(.ui.selection.dropdown),
  .mediumLarge:global(.ui.selection.dropdown .menu.visible) {
    min-width: 200px; // to override semantic-ui default
    width: 200px;
  }
  .medium:global(.ui.selection.dropdown),
  .medium:global(.ui.selection.dropdown .menu.visible) {
    min-width: 184px; // to override semantic-ui default
    width: 184px;
  }
  .small:global(.ui.selection.dropdown),
  .small:global(.ui.selection.dropdown .menu.visible) {
    min-width: 115px; // to override semantic-ui default
    width: 100px;
  }
  .tiny:global(.ui.selection.dropdown),
  .tiny:global(.ui.selection.dropdown .menu.visible) {
    min-width: 88px; // to override semantic-ui default
    width: 88px;
  }

  label {
    font-weight: 700;
    font-size: 15px;
    display: block;
    padding-bottom: 3px;
    &.disabled {
      color: grey;
    }
  }
}

.dropdownInput {
  span:global(.text) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.fluid:global(.ui.selection.dropdown) {
  > :global(.text) {
    width: calc(100% - 1.5em);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.lightVariant:global(.ui.selection.dropdown) {
  &:not(:global(.active)) {
    border-color: transparent;
    background: inherit;
  }
  :global(.icon) {
    color: $primary;
  }
}

.mediumMenu:global(.ui.selection.dropdown .menu) {
  width: 210px;
}

//no pending change
.bulkDropDown:global(.ui.dropdown) {
  .disabled {
    color: grey;
  }
  .regular {
    font-weight: 700;
    font-size: 15px;
    display: block;
  }
  i:global(.caret.down.icon) {
    float: none;
  }
  &.bulkHeader {
    > :global(.visible.menu.transition > div.disabled.item:first-child) {
      border-radius: 4px 4px 0 0;
      margin: -1px;
      color: white;
      background-color: $primary;
      opacity: 1;
      :global(.ui.active.tiny.progress) {
        margin: auto;
      }
      &:hover {
        color: white;
        background-color: $primary;
      }
      :global(.text) {
        display: flex;
        .label {
          flex-grow: 1;
          flex-shrink: 1;
        }
        i {
          cursor: pointer;
          margin-right: -8px;
          color: white;
          background-color: $primary;
        }
      }
    }
  }
}

#customSelection {
  background: inherit;
  border: none;
  max-width: 170px;
}
.label {
  overflow: hidden;
  text-overflow: ellipsis;
}