/* Semantic UI Overrides for design */
@import 'src/variables.scss';

.renewText {
  margin-top: -5px;
}

.oneModal {
  margin-top: 20px;
}

.twoModals {
  margin-top: 45px !important;
}

.slider {
  max-height: 427px !important;
}

.slider-control-bottomcenter {
  height: 0 !important;
  bottom: 0 !important;
}

.closeIcon {
  float: right;
  font-size: 16px !important;
  margin-top: -15px !important;
}

.exclamationTriangle {
  color: $warning;
  position: relative;
  top: 3px;
}

.contentReactModal {
  background-color: white;
  width: 70%;
  max-width: 755px;
  padding: 30px;
  margin-left: 200px;
  text-align: center;
  vertical-align: middle;
  border: none;
  overflow-y: hidden;
  min-height: 540px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-25%, -50%);
  right: 0;
  bottom: 0;
  z-index: 5 !important;
}

.contentReactModalOne {
  min-height: 500px;
  z-index: 4 !important;
}

@media (min-width: 769px) and (max-width: 998px) {
  .contentReactModal {
    max-width: 735px;
    min-height: 500px;
    top: 50%;
    left: 15%;
    transform: translate(-15%, -50%);
  }

  .contentReactModalOne {
    min-height: 500px;
    left: 15%;
    transform: translate(-15%, -50%);
  }

  .slider {
    max-height: 350px !important;
  }

  .slider-control-bottomcenter {
    height: 0 !important;
    bottom: 0 !important;
  }
}

@media (max-width: 768px) {
  .contentReactModal {
    width: 85%;
    max-width: 85%;
    margin-left: 0;
    min-height: 500px;
    position: absolute;
  }

  .contentReactModalOne {
    background-color: white;
    width: 85%;
    min-height: 450px;
    max-width: 885%;
    margin-left: 0;
    border-radius: 4px;
    position: absolute;
  }

  .slider {
    max-height: 300px !important;
  }

  .twoModals {
    margin-top: 85px !important;
    margin-bottom: 0;
  }

  .slider-control-bottomcenter {
    height: 0 !important;
    bottom: -35px !important;
  }
}

#dataTableHeaderoutOfMx,
#dataTableHeadermxExpires {
  width: 60px;
}

/* nuka carousel overrides for design */
.carouselImage {
  max-width: 99%;
}

.carouselContainer {
  padding: 10px;
}

.slider-control-bottomcenter {
  height: 0 !important;
}

.slider-list {
  /* height: 395px!important; */
}

.slider-control-bottomcenter ul li button {
  color: #3f85ca !important;
  font-size: 50px !important;
  padding: 0 !important;
}

/* custom design */
.fixButton {
  margin-top: 5px;
  margin-left: 15px;
  position: relative;
  display: inline-block;

  :global(.ui.button).fixingBasic,
  :global(.ui.button:hover).fixingBasic,
  :global(.ui.button:visited).fixingBasic,
  :global(.ui.button:focus).fixingBasic {
    background-color: #fff;
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    border: 1px #333 solid;
    font-weight: bold;
  }
}

.closeBanner {
  float: right;
  display: inline-flex;
  padding-right: 10px;
  padding-top: 10px;
}

.learnMore {
  text-align: center;
  color: #2987cd;
  font-size: 1rem;
  cursor: pointer;
}

.renewEmailLink {
  color: black;
  text-decoration: underline;
}
