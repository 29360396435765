@import 'src/variables.scss';

.exclamationTriangle {
  color: $warning;
  position: relative;
  top: 3px;
  left: -5px;
}

.exclamationTriangleElement {
  color: $element-grey;
  position: relative;
  top: 3px;
  left: -5px;
}
