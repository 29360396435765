.filterButton:global(.ui.button) {
  border: 2px solid #1279c6;
  color: #1279c6;
  background-color: white;
  font-size: 15px;
  padding: 8px;
  padding-bottom: 7px;
  width: 100px;
  margin: 0px;
  span {
    position: relative;
    top: -3px;
    left: 0px;
  }
}
