.usageChart {
  height: 100%;
}

.segment {
  height: 100%
}

.link {
  text-align: right;
}

.body {
  display: flex;
  flex-direction: column;
  height: 400px;
  flex: 1;
}
