.fixButton {
  margin-top: 5px;
  margin-left: 15px;
  position: relative;
  display: inline-block;

  :global(.ui.button).fixingBasic,
  :global(.ui.button:hover).fixingBasic,
  :global(.ui.button:visited).fixingBasic,
  :global(.ui.button:focus).fixingBasic {
    background-color: #fff;
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    border: 1px #333 solid;
    font-weight: bold;
  }
}

.closeBanner {
  float: right;
  display: inline-flex;
  padding-right: 10px;
  padding-top: 10px;
}