.marker-cluster-pods div {
  border: rgba(174, 207, 253, 0.75) 5px solid;
  background-color: #3388ff;
  z-index: 400;
}

.marker-cluster-buildings div.building {
  border: rgba(157, 235, 224, 0.75) 5px solid;
  background-color: #88d7cd;
  z-index: 400;
}

.marker-purple-cluster-pods div {
  border: rgba(211, 245, 218, 0.75) 5px solid;
  background-color: #163296;
  z-index: 400;
}

.marker-cluster-alerts div {
  border: #fcb810 5px solid;
  background-color: #fcb810;
  z-index: 400;
  /* z-index: 400;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 25px 15px;
  border-color: transparent transparent #ffbf00 transparent;
   */
  position: relative;
}

.alerts-kepler-marker-cluster-small {
  right: -80%;
  bottom: -50%;

}

.alerts-kepler-marker-cluster-medium {
  right: -180%;
  bottom: -150%;

}

.alerts-kepler-marker-cluster-large {
  right: -200%;
  bottom: -175%;

}

.alerts-kepler-marker-cluster-x-large {
  right: -275%;
  bottom: -175%;
}

.alerts-kepler-marker-cluster-small {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.alerts-kepler-marker-cluster-medium {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
}

.alerts-kepler-marker-cluster-large {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.alerts-kepler-marker-cluster-x-large {
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
}

.kepler-marker-cluster-small div {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.kepler-marker-cluster-medium div {
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
}

.kepler-marker-cluster-large div {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.kepler-marker-cluster-x-large div {
  width: 45px;
  height: 45px;
  border-radius: 22.5px;
}

.kepler-marker-cluster-small div,
.kepler-marker-cluster-medium div,
.kepler-marker-cluster-large div,
.kepler-marker-cluster-x-large div,
.alerts-kepler-marker-cluster-small,
.alerts-kepler-marker-cluster-medium,
.alerts-kepler-marker-cluster-large,
.alerts-kepler-marker-cluster-x-large {
  padding: 0px;
  margin: 0px;
  padding-top: 15%;
  text-align: center;
  text-align: center;
  font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 900;
  color: black;
}

.kepler-marker-cluster-medium div,
.alerts-kepler-marker-cluster-medium {
  padding-top: 30%;
}

.kepler-marker-cluster-large div,
.alerts-kepler-marker-cluster-large {
  padding-top: 60%;
}

.kepler-marker-cluster-x-large div,
.alerts-kepler-marker-cluster-x-large {
  padding-top: 75%;
}

.alerts-kepler-marker-cluster-small>span,
.alerts-kepler-marker-cluster-medium>span,
.alerts-kepler-marker-cluster-large>span,
.alerts-kepler-marker-cluster-x-large>span {}

.alerts-kepler-marker-cluster-medium>span,
.alerts-kepler-marker-cluster-large>span {
  /* left: -7px; */

}

.alerts-kepler-marker-cluster-x-large>span {
  /* left: -10px; */
}

.checkbox-background-yellow>.ui.checkbox input:checked~.box::after,
.checkbox-background-yellow>.ui.checkbox input:checked~label::after {
  background: #fcb810 !important;
}