@import 'src/_mixins';
.sds-card {
  width: 350px;
  min-width: 350px;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  margin: auto;
  height: 424px;
  margin-bottom: 16px;

  * {
    @include light-scrollbar;
  }

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border: 1px #f8f8f8 solid;
  border-radius: 4px;

  .table-text {
    max-width: 220px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  position: relative;

  h2 {
    text-align: center;
    font-size: 20px;
  }

  :global(.ui.collapsing.table).table {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }

  .table-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .table-header {
    border-bottom: #e9e9e9 solid 1px;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    padding-bottom: 8px;
  }

  .count {
    text-align: right;
  }

  :global(.ui.table tr td) {
    border-top: 0;
  }

  .table-container {
    height: 320px;
    overflow-y: auto;
  }
}
