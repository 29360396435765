@import 'src/variables';

.chartContainer {
  flex-wrap: wrap;
  min-height: 370px;
  margin: 0 auto;
  @media screen and (min-width: 730px) {
    max-width: 732px;
    &:global(.react-multi-carousel-track) {
      max-width: 100%;
    }
  }

  .topRow {
    &:global(.react-multi-carousel-list) {
      display: block;
      flex: 1;
    }

    :global(.ui.card) {
      margin-left: auto;
      margin-right: auto;
    }

    :global(.react-multi-carousel-track) {
      float: left;
    }

    button {
      top: 44%;
      opacity: 0;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}
