@import 'src/variables.scss';

.templateCard {
  .groupHeader {
    margin-left: 8px;
    display: flex;
    padding-top: 16px;

    h3 {
      margin: 0;
      font-size: 20px;
    }

    .templateIcon {
      fill: #2a85d0;
      height: 24px;
      width: 24px;
      margin-right: 0.5em;
    }

    &.unmanageable {
      .templateIcon {
        fill: $dark-grey;
      }

      h3 {
        color: $dark-grey;
      }
    }
  }
}
