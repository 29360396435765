@import 'src/variables.scss';

.configWrapper {
  min-height: 335px;
  margin-left: 16px;
  border-right: 1px solid #e5e5e5;
  .passwordRules {
    margin-top: -5px;
    font-size: 11px;
    font-style: italic;
    margin-left: 28px;
    .rule {
      line-height: 4px;
    }
    .rule:last-child {
        margin-bottom: 15px;
    }
  }
  .modal {
    font-weight: bold;
    margin: 20px;
  }
}

.certWrapper {
  margin-left: 32px;
  .encryptionWrapper {
    margin-bottom: 16px;
    margin-left: 25px;
    .encryption {
      margin-top: 16px;
    }
  }
  .caCert {
    margin-left: 25px;
  }
}

.fileUpload {
  padding-top: 12px;
}

@media (max-width: $md-max) {
  .configWrapper {
    min-height: 0px;
    margin-left: 16px;
    border-bottom: 1px solid #e5e5e5;
    border-right: none;
    padding-bottom: 8px;
    .password {
      margin-top: -8px;
    }
  }

  .certWrapper {
    margin-left: 25px;
    margin-top: 8px;
    .encryptionWrapper {
      margin-bottom: 24px;
    }
  }
}
