.metricCard {
  &:global(.ui.card) {
    padding-top: 16px;
    box-sizing: border-box;
    margin: 16px 0 0 0;
    align-self: center;
    height: 270px;
    > :global(.content) {
      padding-left: 16px;
      padding-right: 16px;
    }
    .metric {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      z-index: 1;
      &.zero {
        :global(.button) {
          margin-top: 0px;
          transform: translateY(0);
        }
        .count {
          color: #bf1244;
        }
      }
      .count {
        color: #009fc9;
        font-size: 100px;
        font-weight: 300;
        line-height: 120px;
        margin-bottom: -8px;
        margin-top: -32px;
      }
      .label {
        font-size: 20px;
        line-height: 24px;
        .unit {
          font-weight: 900;
        }
      }
      .note {
        margin: 8px auto;
        max-width: 200px;
        height: 36px;
        color: #1b1c1d;
        font-size: 12px;
        line-height: 15px;
      }
      :global(.button) {
        margin: 70px auto 16px;
        transform: translateY(-50%);
      }
    }
    &.chart {
      margin-left: 0px;
      .chartContainer {
        max-width: 360px;
        margin: -37px auto;
        .metric {
          margin-left: -54px;
          margin-bottom: -204px;
          margin-top: 32px;
          &.zero {
            margin-top: 70px;
            margin-bottom: -270px;
          }
          .count {
            margin-top: -64px;
          }
          .note {
            max-width: 150px;
          }
        }
      }
    }
  }
}
