@import "src/variables.scss";

.maintenanceBanner {
    padding: 5px;
    padding-left: 1em;
    background-color: #D6E6F6;

    .infoIcon {
        padding-right: 7px;
    }
}

.text {
    color: black;

    .button, .button:enabled {
        padding-left: 5px;
        color: $blue;
        background-color: inherit ;
    }
    .button:hover{
        color: #155d94;
        background-color: inherit ;
    }
}

.header {
    text-align: left;
}

.list {
    padding-left: 30px;
    text-align: left;
    font-size: 16px;
}

.buttonContatiner {
    text-align: right;
}
