@import 'src/variables.scss';

.card:global(.ui.card) {
  position: fixed;
  top: 32px;
  right: 4px;
  z-index: $zIndexHigh;
  :global(.content) {
    padding: 12px;
    display: flex;
  }
  .closeIcon {
    fill: $grey;
  }
}
