.checkbox {
  padding: 0 16px 16px 0;
  .bold {
    font-size: 15px;
    font-weight: 700;
  }
}
.noPadding {
  padding: 0 0px 16px 0;
  .bold {
    font-size: 15px;
    font-weight: 700;
  }
}

.note {
  margin-top: 0.25em;
  font-size: 12px;
  font-weight: 300;
}

.notification:before {
  content: " ";
}

.syncStateIcon {
  margin-left: 3px;
}