.what-card {
  height: 370px;
  :global(.ui.header) {
    margin-top: 0;
    font-size: 20px;
  }
}

.grouping-options {
  display: flex;
}

.header-container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding-right: 25px;
}

.pagination-container {
  align-items: center;
  display: flex;
  margin-bottom: 14px;
}

.grouped-check {
  margin-left: 24px;
  padding: 0;
}

i.arrow {
  margin: 0;
}

.controls {
  align-items: center;
  display: flex;

  :global(.ui.button) {
    margin: 0;
    padding: 0;
  }
}

.title {
  margin-bottom: 14px;
}

.fullHeight {
  height: 100%
}