@import 'src/variables.scss';

.map {
  margin: 0.3% auto;
  height: 98%;
  min-height: 300px; // for safari
  width: 99%;
  z-index: $zIndexLow;
  border-radius: 5px;
}

.podDot {
  z-index: $zIndexLow;
}

.displayNone {
  display: none;
}

.displayNone {
  display: none;
}

.podPageButton {
  border-radius: 5px;
  margin-top: 20px;
  height: 27px;
  min-width: 120px;
  line-height: 10px;
  background-color: white;
  border: 1px #1279c6 solid;
  color: #1279c6;
  position: absolute;
  bottom: -59px;
  left: -15px;

  &:hover {
    background-color: lightgrey;
  }
}

.map-tooltip {
  margin-top: 25px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  overflow-x: auto;
  word-wrap: break-word;
  padding: 7px;

  p {
    font-size: 13px;
    line-height: 12px;
    margin: 0px;
    padding: 1px;
  }
}

.leaflet-container {
  background-color: #d4dadc;
}