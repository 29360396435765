@import 'src/variables.scss';

.icon {
  min-width: 15px;
  display: inline-block;
  :global(.loader) {
    z-index: unset;
  }
}

:global(i.icon).notYetDeployed {
  color: #00add3 !important;
}

:global(i.icon).unsupported {
  color: #e7e8e9 !important;
}

.minVersionText {
  font-size: 14px;
  .updateNow {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    color: $blue;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.loading {
  margin-right: 5px;
}

.tooltip:global(.ui.popup.transition.visible) {
  margin-left: -12px;
}
