@import 'src/variables.scss';
.pie {
  padding-right: 10px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .title {
    font-size: 20px;
    font-weight: 700;
  }

  .pagination {
    max-width: 100px;
  }

  .pagingButtons {
    z-index: $zIndexLow;
    max-width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .iconLeft {
      height: 20px;
      cursor: pointer;
    }
    .iconRight {
      height: 20px;
      cursor: pointer;
    }
  }
}

.infoSection {
  .paging {
    width: 260px;
  }
  .legend {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    padding-right: 0px;
    line-height: 1em;
    .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      font-size: 12px;
      .circleIcon {
        margin-right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        align-self: center;
      }
      .text {
        display: flex;
        justify-content: center;
        flex: 1;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: bold;
        .metric {
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          overflow: hidden;
          padding-right: 4px;
        }
      }

      .rightSide {
        flex-shrink: 0;
        margin-top: 5px;
      }
    }
  }
  .disabled {
    fill: #c8c8c8;
  }
  .enbled:hover {
    fill: #c8c8c8;
  }
  .legend {
    max-height: 180px;
  }
  :global(.externalLegend){
    max-height: 180px;
    padding-bottom: 35px;
    margin-left: -45px;
  }
}

.clickable {
  cursor: pointer;
  font-weight: bold;
  color: $primary;
}
