.header {
  margin-top: 25px !important;
}
:global(i.icon).dropdownCheck{
  margin-left: 5px;
}
.roleOptions {
  line-height: 1em;
  font-size: 1rem;
  padding: 0.7857rem 1.142686rem;
  .roleSpace {
    margin-right: 0.78571429rem !important;
  }
  .dropdownCaret {
    float: right;
  }
}

.roleOptions:hover {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.95);
  cursor: pointer;
}

.showOptions {
  display: block;
  position: absolute;
  top: 0px;
  left: -136px;
  border: rgba(34, 36, 38, 0.15);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.28571429rem;
  z-index: 14;
  background: white;
  width: 220px;
  line-height: 1em;
  font-size: 1rem;
  .roleWrapper {
    width: 100%;
    height: 100%;
    line-height: 1em;
    font-size: 1rem;
    padding: 0.7857rem 1.142686rem;
    .roleCheckmark {
      float: right;
    }
  }
}

.roleWrapper:hover {
  color: rgba(0, 0, 0, 0.95);
  background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.centerButton {
  text-align: center;
}

.disabled {
  color: lightgray;
}

.disabled:hover {
  background: inherit;
  color: lightgray;
  cursor: default;
}
.ssoOption {
  width: 100%
}