.scroll-wrapper {
  height: 100%;
  max-height: 250px;
  padding: 25px;
  overflow-y: auto;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
