@import 'src/_variables';

.header {
  display: flex;
  justify-content: space-between;

  :global(.ui.header .sub.header).subHeader {
    color: black;
    font-weight: 400;
    font-size: 14px;
    padding-top: 5px;
    margin-bottom: 8px;

    label {
      font-weight: normal;
    }
  }
}

.table-container {
  margin-top: 10px;
}

.top-pills-container {
  margin-left: 0px;
  display: flex;

  [class*='circular'] {
    margin-bottom: 0.3em;
  }

  >div {
    width: 65%;
  }

  label {
    font-size: 16px;
    line-height: 19px;
    width: 100px;
    margin-bottom: 0.3em;
  }

  @media (max-width: $lg-max) {
    flex-direction: column;
  }
}

.topCarousel {
  margin-bottom: 16px;
  padding: 0px;
}

.bottomCards {
  padding: 0px;
  margin: 0px;
  padding-bottom: 16px !important;
}

.topRow {
  margin: 0px auto;

  :global(.ui.card) {
    width: 450px;
    height: 255px;
    margin: 2px 8px;

    @media screen and (max-width: 1575px) {
      margin: 2px auto;
    }

    :global(.content) {
      padding: 16px;
    }
  }

  :global(.legend) {
    max-height: 100%;
  }

  &:global(.react-multi-carousel-list) {
    display: block;
  }

  :global(.react-multi-carousel-track) {
    float: left;
  }

  button {
    top: 44%;
    opacity: 0;

    @media screen and (max-width: 1575px) {
      opacity: 0.25;
    }
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  @media screen and (min-width: 1140px) {
    max-width: 932px;
  }

  @media screen and (min-width: 1651px) {
    max-width: 1400px;
  }
}

.bottom-row {
  margin: auto 0px;
  padding: 0px;

  :global(.ui.card) {
    min-height: 375px;
    margin: auto;
    width: 100%;

    :global(.content) {
      padding-bottom: 0;
    }
  }

  :global(.legend) {
    max-height: 100%;
  }

  &:global(.react-multi-carousel-list) {
    display: block;
  }

  :global(.react-multi-carousel-track) {
    float: left;
  }

  :global(.react-multiple-carousel__arrow) {
    z-index: 500;
  }

  :global(.react-multi-carousel-item) {
    >div {
      margin: 0px;
      padding: 1px;
      padding-right: 8px;
      padding-left: 8px;
    }
  }

  button {
    top: 44%;
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
}

.filterSection {
  display: flex;
}

.filterButton {
  margin-right: 8px;
}

.row {
  margin-bottom: 16px;
}

:global(.ui.card).solCard {
  border: 1px #f8f8f8 solid;
  border-radius: 4px;
  margin: 0px;
  margin-bottom: 16px;
  width: 100%;
  min-height: 117px;
  z-index: 599;
  padding: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 1;

  &.shadow {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}
.chartWrapper {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.chart {
  margin-right: 10px;
  min-width: 500px;
  flex: 1;
}