.parent {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding-bottom: 15px;
}
.padBottom {
  padding-bottom: 15px;
}
