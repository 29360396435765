.landingPage {
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  .logo {
    width: 500px;
  }
  .description {
    padding-top: 16px;
    padding-bottom: 8px;
    width: 504px;
    font-size: 20px;
    line-height: 26px;
    margin: auto;
  }
  .getStarted {
    margin-top: 16px;
    padding: 14px 56px;
    font-size: 18px;
    font-weight: bold;
  }
}
.podsPage {
  margin-left: 16px;
  margin-right: 12px;
}

.aboveTable {
  display: flex;
  flex-direction: row;
  margin-bottom: -42px;
  .screenKey {
    margin: 24px 0 8px;
  }
  .aboveTableItem {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }
}

.enableTrial {
  float: right;
}
