.daily-card {
  display: flex;
  .header {
    margin-bottom: 16px !important;
    font-size: 20px !important;
  }
}
.content {
  display: flex;
  justify-content: space-between;
  .chart {
    display: block;
  }
}

.result {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.label {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #3a3a3a;
}
.value {
  color: #70bf44;
  font-size: 27px;
  line-height: 33px;
  font-weight: bold;
}
