@import 'src/_variables';

.name {
  display: flex;
  .input {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .text {
    flex-grow: 1;
    margin-top: auto;
    margin-bottom: auto;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .edit {
    flex-shrink: 0;
    margin: auto 0 auto 4px;
    .icon {
      cursor: pointer;
      color: $primary;
      width: 18px;
    }
  }
}

.wrapper {
  margin: 16px;

  .fixedWidth {
    width: 250px;
  }
  .fixedWidthFloors {
    width: 150px;
  }
  .widthSize {
    min-width: 300px;
  }
  :global(.ui.table) {
    td.building {
      padding-top: 0;
      padding-bottom: 0;
      height: 48px;
    }
  }
}
.tooltip {
  width: 100%;
  .content {
    display: flex;
    .icon {
      flex-shrink: 0;
    }
  }
}
