@import 'src/variables.scss';

.colorBox {
    height: 19.89px;	
    width: 30px;	
    border: 1px solid #1B1C1D;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    position: relative;
    top: 5px;
    display: inline-block;
}
.picker {
    position: absolute;
    bottom: 15%;
    z-index: 1001;
    :global(.chrome-picker ),
    :global(.chrome-picker ) * {
        z-index: 1001;
    }
}
.clickOut {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexHigh;
}
