@import 'src/variables.scss';

.cards {
  margin: 0px;
}

.topRow {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  @media screen and (max-width: 1575px) and (min-width: 1140px) {
    width: 975px;
    width: 100%;
  }
  @media screen and (max-width: 1140px) {
    width: 510px;
    width: 100%;
  }

  :global(.ui.card) {
    width: 450px;
    height: 255px;
    margin: 2px 8px;
    @media screen and (max-width: 1140px) {
      margin: 2px auto;
    }

    :global(.content) {
      padding: 16px;
    }
  }

  :global(.legend) {
    max-height: 100%;
  }
  &:global(.react-multi-carousel-list) {
    display: block;
  }
  :global(.react-multi-carousel-track) {
    float: left;
    width: 100%;
  }

  :global(.react-multi-carousel-item) {
    padding: 0px auto;
    width: auto !important;
    min-width: 468px;
  }

  button {
    top: 44%;
    right: 0%;
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
}
