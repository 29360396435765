.cancelSaveRP {
  // margin-top: 24px;
  float: right;
  button {
    margin-right: 0;
  }
}

.buttonWrapper {
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  align-items: center;
}

.header {
  display:flex;
  padding: 16px 0;
  font-size: 22px;
  font-weight: 700;
  .icon {
    margin-left: 5px;
  }
}
.subHeader {
  font-size: 16px;
  margin-bottom:16px;
}

.routePresetEdit {
  padding-bottom:4px;
  display: flex;
  justify-content: space-between;
}

.routePresetHeaderName {
  font-weight: 700;
  font-size: 22px;
  padding-top: 14px;
}

.routePresetDropdown {
  width: 300px;
  .dropdownInput {
    width: 300px !important;
  }
}

.routePresetEllipsesDropdown{
  display: inline;
  float: right;
}

.rpMenu{
  display:inline;
  overflow:visible;
  position: fixed;
}

.routePresetDropdownOption {
  display:inline;
  overflow:visible;
}

.routePresetDropdownOptionName {
  display:inline;
  overflow:visible;
}

.buttonHolder{
  display:flex;
  justify-content: space-between;
}
.addPodsNextButton {
  margin-top: 16px !important;
}
.addPodsBackButton {
  margin-top: 16px !important;
}

.savedPresetsDropdown {
  padding:16px;
  padding-right:36px;
}