@import 'src/variables.scss';

.updateNotification {
  width: 360px;

  .cardContent {
    display: flex;
    flex-grow: 1;
    font-size: 16px;

    .refreshIcon {
      cursor: pointer;
      fill: $primary;
      width: 20px;
      margin-right: 8px;
    }
  }
}

.header {
  padding: 16px 0 24px 0;
  font-size: 20px;

  .grey {
    color: grey;
  }

  i {
    margin: 0 10px;
  }

  .helpTooltip {
    margin-left: -5px;

    .questionIcon {
      height: 24px;
      width: 24px;
      position: relative;
      right: -8px;
      top: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.plus {
  position: relative;
  left: -10px;
}

:global(.ui.icon.message).createNew {
  margin: 16px 0;
  height: 60px;
  width: unset;
  background-color: $primary;
  color: white;

  :global(.icon:not(.close)) {
    opacity: 1;
    font-size: 2em;
  }

  cursor: pointer;
}
