@import 'src/variables.scss';

.templateWrapper {
  margin: 16px;
  :global(.ui.grid>.row>.column ) {
    padding: 0 8px 0 8px;
  }
  .thumbnail {
    margin-left: 8px;
    margin-bottom: 8px;
  }
  .columnWrapper{
    margin-top: 28px;
    margin-right: -12px;
    max-width: 275px;
  }
  .options {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
  .helpText {
    font-size: 12px;
    padding-left: 8px;
    margin-bottom: 8px;
    border-left: 2px solid $primary;
    span {
      font-style: italic;
    }
  }
  .customInstructions {
    height: 300px;
  }
  .colorWrapper {
    display: inline;
    .colorText {
      padding: 0px 8px 0 0px;
    }
    .colorText:hover {
      cursor: pointer;
    }
  }
  .colorText:hover {
    cursor: pointer;
  }
  .previewWrapper {
    max-width: 570px;
  }
  .welcomePreview {
    height: 60%;
    min-height: 140px;
    width: 90%;
    min-width: 343px;
    border: 1px solid grey;
    background-color: lightgrey;
  }
  .middleColumnWrapper {
    max-width: 297px;
  }
  .hostnameOptions {
    padding-left: 26px;
    margin-top: -5px;
  }
}

.classicWarning {
  text-align: center;
  margin: 32px;
  .warningText {
    font-size: 25px;
    font-weight: bold;
    margin: 32px;
  }
}
.modernList {
  margin-left: -20px;
}
.spanMargin {
  margin-top: 20px;
}
