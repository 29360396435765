.card {
  flex: 1;
}

.card-content {
  display: flex;
  flex: 1;
}

.sub-title {
  margin-top: -15px;
  margin-bottom: 15px;
}

.custom-tooltip {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  width: 150px;
  height: auto;
  padding: 5px;
}

.user-mobility-chart {
  display: flex;

  :global(.ui.card) {
    :global(.content) {
      padding-bottom: 5px;
    }
  }
  .vendors-legend {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 100px;
  }
}

.header {
  font-size: 20px;
  margin-bottom: 16px;
}

.subheading {
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.chart {
  flex: 1;
}

.chart-container {
  min-height: 256px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
