@import 'src/variables';

.elementItemTextContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  .infoIcon {
    fill: $element-blue;
    height: 16px;
  }
  .infoText {
    font-size: 12px;
  }
}
