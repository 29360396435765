@import 'src/variables.scss';
.wrapper {
  top: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  height: 30px;
  z-index: $zIndexPriority;
  .succeeded, .error {
    color: white;
    vertical-align: middle;
    font-size: 14px;
    font-weight: normal;
    border-radius: 4px;
    padding: 5px 24px 8px 24px;
  }
  .succeeded {
    background-color: #66cc33;
  }
  .error {
    background-color: #db2828;
  }
}
