@import 'src/variables.scss';

.mirrorWrapper {
  display: flex;

  .welcomePreview {
    .flashing {
      animation-duration: 1s;
      animation-name: flash;
    }

    @keyframes flash {
      from {
        background-color: rgba($color: #ffffff, $alpha: 0);
      }

      50% {
        background-color: rgba($color: #ffffff, $alpha: 1.0);
      }

      to {
        background-color: rgba($color: #ffffff, $alpha: 0);
      }
    }

    max-width: 100%;
    max-height: 100%;
    height: 160px;
    width: 285px;
    border: 1px solid grey;
    background-color: lightgrey;
    margin-bottom: 8px;
    position: relative;
    color: white;
    display: inline-block;
    margin-right: 2px;

    .presenceBar {
      background-color: #1B1C1D;
      z-index: $zIndexHigh;
      bottom: 0;
      width: 100%;
      opacity: 0.7;
      height: 10px;
      position: absolute;
      font-size: 7px;

      .pbRoomName,
      .ipAddress,
      .pbScreenKey {
        padding: 1px;
        display: inline-block;
        margin-top: -5px;
        line-height: 5px;
        position: relative;
        top: -5px;
      }
    }

    .msScreenKey {
      position: absolute;
      top: 29px;
      left: 8px;
      font-size: 10px;
    }

    .clock {
      position: absolute;
      bottom: 24px;
      left: 8px;
      font-size: 12px;
    }

    .wifi {
      position: absolute;
      bottom: 16px;
      left: 8px;
      font-size: 7px;
      background-color: #1B1C1D;
      opacity: .7;
      color: white;
      line-height: 8px;

      .wifiIcon {
        position: relative;
        top: 1px;
        color: #21BA45;
        height: 9px;
        width: 9px;
        padding: 0px;
        margin: 0px;
      }
    }

    .msRoomName {
      position: absolute;
      top: 12px;
      left: 8px;
      font-weight: bold;
      font-size: 15px;
    }

    .sideBar {
      background-color: #1B1C1D;
      z-index: 100;
      height: 100%;
      width: 80px;
      right: 0;
      top: 0;
      opacity: 0.7;
      position: absolute;
      padding: 8px;

      .instructions {
        top: 2px;
        width: 69px;
        margin-left: -2.5px;
        display: block;
      }

      .airplay,
      .miracast {
        margin: 5px 2.5px;
        margin-bottom: 0;
        width: 59px;
        display: block;
      }

      .calendar {
        margin: 2.5px;
        height: 35px;
        position: absolute;
        top: 50%;
        width: 59px;
      }
    }

    .singleImage {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
