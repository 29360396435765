@import 'src/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background-image: url(../../assets/cloud_login_desktop.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  @media(max-width: $md-min) {
    background-image: url(../../assets/cloud_login_small.png)
  }
  .loginLogo {
    height: 100px;
    width: 350px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .contentWrapper {
    text-align: left;
  }
  
  .star{
    &:after{
      content: '*' !important;
      color:#db2828
    }
  }

}  
:global(.ui.checkbox) {
    &:after{
      content: none !important;
      
    }
 }

.passwordRequirements {
  align-self: flex-start;
  text-align: left;
}

.list {
  padding-left: 12px;
  margin-top: 5px;
}