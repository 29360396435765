.modal {
  font-weight: bold;
  margin: 20px;
}

.wrapper {
  margin-left: 16px;
  .subItem {
    margin-left: 32px;
  }
  .timePicker {
    margin-bottom: 16px;
  }
  .questionIcon {
    margin-right: 0;
  }
  .spanMargin {
    margin-top: 20px;
  }
}
