.tinyContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.massiveContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
}