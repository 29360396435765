@import 'src/variables.scss';

// Header Styles
.headerWrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  padding-bottom: 32px;
  .header {
    flex: 1 1 0;
    text-align: center;
    :global(.ui.header .sub.header).subHeader {
      color: black;
      font-weight: 400;
      font-size: 12px;
      padding-top: 5px;
    }
  }
} // End Header Styles

.categorySelector {
  z-index: $zIndexHigh;
}
// Options Styles
.optionsParent {
  max-height: 140px;
  margin-left: 60px;
  overflow-y: auto;
  .checkbox {
    padding-left: 16px;
    font-size: 0.8em;
    border-left: 1px solid $grey;
    margin: 8px 0px 12px -1px;
    display: inline-block;
    top: 4px;
    min-width: 137.5px;
    min-height: 24px;
    label {
      margin-left: 8px;
      margin-right: 12px;
      font-weight: bold;
      vertical-align: middle;
      &.pointer {
        cursor: pointer;
        padding-right: 8px;
      }
    }
    svg {
      cursor: pointer;
      vertical-align: middle;
    }
    .tick {
      stroke-dasharray: 24;
      stroke-dashoffset: 24;
      transition: stroke-dashoffset 0.1s cubic-bezier(1, 0, 0.55, 0.41);
    }
    .square {
      fill-opacity: 0;
      transition: fill-opacity 0.08s ease-in-out;
    }
    &.checked .tick {
      stroke-dashoffset: 0;
      transition-delay: 0.02s;
    }
    &.checked .square {
      fill-opacity: 1;
    }
    &.unchecked .tick {
      stroke-dashoffset: 24;
    }
    &.unchecked .square {
      fill-opacity: 0;
      transition-delay: 0.1s;
    }
  }
} // End options Styles

// Chart Styles
.chartWrapper {
  position: relative;
  height: 320px;
  :global(.recharts-wrapper) {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .label {
    font-weight: 900;
  }
} // End Chart Styles

// Category Select Styles
.selectWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 80px;
}

.rightMenu {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.elementMessageContainer {
  margin-left: auto;
  padding-right: 80px;
}
