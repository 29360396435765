@import 'src/variables.scss';

@media (min-width: $md-min) {
  .mapWrapper {
    padding-left: 100px;
    padding-right: 100px;
    margin: 0 auto;
  }
}

@media (max-width: $md-min) {
  .mapWrapper {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}

.filterAllPods {
  width: 230px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  :global(input.hidden + label) {
    color: white;
  }
  :global(input.hidden:focus + label) {
    color: white;
  }
  z-index: $zIndexHigh;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
}

.mapWrapper {
  .mapCard:global(.ui.card) {
    height: 450px;
    width: inherit;
    z-index: $zIndexLow;

    > :global(.content) {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      padding: 0px;
      margin: 0px;

      .borderActive {
        margin-top: 0.2%;
        border: 4px solid #66cc33;
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
      .border {
        margin-top: 0.2%;
        border: 4px solid transparent;
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
      .map {
        margin: 0.3% auto;
        height: 98%;
        min-height: 425px; // for safari
        width: 99%;
        z-index: $zIndexLow;
        border-radius: 5px;
      }
      .PodsOrBuildingsButtons {
        z-index: $zIndexHighest;
        position: absolute;
        bottom: 0px;
        left: 10px;
      }
      .buildingCover {
        stroke: #88d7cd;
        &:hover {
          stroke: #88d7cd;
        }
      }
      .dropdownFloorCount {
        z-index: $zIndexPriority;
      }
      .buildingPopUp {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 5px;
        width: 170px;
        margin: 0px !important; // to override semantic-ui default
        padding: 10px !important; // to override semantic-ui default
        z-index: $zIndexHighest;
        position: absolute !important; // to override semantic-ui default
        left: 8px !important; // to override semantic-ui default

        color: white;
        font-size: 13px;
        line-height: 16px;
        .name {
          font-weight: 700;
          font-size: 15px;
        }
        :global(.leaflet-popup-content-wrapper) {
          padding: 0px;
          margin: 0px;
        }

        :global(.leaflet-popup-content) {
          color: white !important;
        }
        :global(.ui.selection.dropdown) {
          margin: 0px;
          margin-top: 16px;

          min-width: 150px !important; // to override semantic-ui default
          width: 150px !important; // to override semantic-ui default
          z-index: $zIndexHighest;
        }
        :global(.SolDropdown-module__dropdown___1ijwf) {
          padding-bottom: 8px !important;
          padding: 0px !important;
          margin: 0px !important;
          max-width: 140px !important;
        }
      }

      .podDot {
        z-index: $zIndexLow;
      }
      .podPageButton {
        border-radius: 5px;
        margin-top: 20px;
        height: 27px;
        min-width: 120px !important;
        line-height: 10px;
        background-color: white;
        border: 1px #1279c6 solid;
        color: #1279c6;
        position: absolute;
        bottom: -59px;
        left: -15px;
        &:hover {
          background-color: lightgrey;
        }
      }
      .displayNone {
        display: none;
      }
      :global(.leaflet-popup-content-wrapper) {
        padding: 0px;
        margin: 0px;
        background-color: transparent;
        box-shadow: none;
        display: block;
      }
      :global(.leaflet-popup-tip) {
        display: none;
      }
    }
    :global(.leaflet-control-zoom.leaflet-bar.leaflet-control) {
      margin-right: 13px;
      margin-top: 44px;
      z-index: $zIndexLow;
    }
    :global(.leaflet-tooltip.leaflet-zoom-animated.leaflet-tooltip-bottom::before, .leaflet-popup-tip-container) {
      display: none;
    }
  }
}
