@import 'src/variables.scss';

.card:global(.ui.card) {
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  padding-left: 100px;
  padding-right: 100px;
  margin: 0 auto;
  height: 450px;
  padding: 16px;
  .header {
    display: flex;
    > .title {
      flex-grow: 1;
      margin-top: 0;
      font-size: 20px;
    }
    .legend {
      ul {
        margin-top: 0;
      }
      > .title {
        text-align: right;
        font-weight: bold;
      }
    }
  }
  > :global(.content) {
    padding: 0;
    margin: 0;
    .footer {
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 30px;
      margin-top: 24px;
      .title {
        font-size: 20px;
        margin-bottom: 0;
      }
      .left {
        margin-top: auto;
        margin-bottom: auto;
        padding-right: 48px;
        .label {
          font-size: 16px;
          padding-bottom: 8px;
          .link {
            font-weight: bold;
          }
        }
        border-right: solid 1px $light-grey;
      }
      .left + .right {
        padding-left: 48px;
      }
      .right {
        .counts {
          display: flex;
          .metric {
            display: flex;
            align-items: center;
            .label {
              .count {
                font-weight: bold;
              }
              position: relative;
              line-height: 20px;
              padding: 8px 12px 8px 4px;
            }
          }
        }
      }
    }
  }
}
