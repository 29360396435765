@import 'src/variables.scss';

@media (min-width: $md-min) {
  .mapWrapper {
    padding-left: 100px;
    padding-right: 100px;
    margin: 0 auto;
  }
}

@media (max-width: $md-min) {
  .mapWrapper {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}

.mapWrapper {
  .mapCard:global(.ui.card) {
    height: 450px;
    width: inherit;
    z-index: $zIndexLow;

    :global(.leaflet-control-zoom.leaflet-bar.leaflet-control) {
      margin-right: 13px;
      margin-top: 44px;
      z-index: $zIndexLow;
    }
    :global(.leaflet-tooltip.leaflet-zoom-animated.leaflet-tooltip-bottom::before, .leaflet-popup-tip-container) {
      display: none;
    }

    > :global(.content) {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      padding: 0px;
      margin: 0px;
      .borderActive {
        margin-top: 0.2%;
        border: 4px solid #66cc33;
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }
      .border {
        margin-top: 0.2%;
        border: 4px solid transparent;
        border-radius: 5px;
        width: 100%;
        height: 100%;
      }

      :global(.leaflet-popup-content-wrapper) {
        padding: 0px;
        margin: 0px;
        background-color: transparent;
        box-shadow: none;
      }
      :global(.leaflet-popup-tip) {
        display: none;
      }

    }
  }
}

.map {
  margin: 0.3% auto;
  height: 98%;
  min-height: 425px;
  width: 99%;
  z-index: $zIndexLow;
  border-radius: 5px;
}

.podDot {
  z-index: $zIndexLow;
  > svg[class*="mapDot"] {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 0;
    left: 0;
  }
}

.displayNone {
  display: none;
}

.map-tooltip {
  margin-top: 25px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  overflow-x: auto;
  word-wrap: break-word;
  padding: 7px;
  p {
    font-size: 13px;
    line-height: 12px;
    margin: 0px;
    padding: 1px;
  }
}

.statuses-list {
  z-index: $zIndexHighest;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #FFFFFF;
  width: 163px;
  height: 121px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding-right: 10px;
  ul {
    margin-top: 8px;
    margin-bottom: 0;
  }
  li {
    list-style: none;
    margin-left: -30px;
    padding-bottom: 4px;
    label {
      font-size: 12px;
      font-weight: 700;
      line-height: 14.4px;
    }
  }
}
