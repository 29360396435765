@import 'src/functions';
@import 'src/_mixins';

.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.menu {
  width: 225px;
  align-self: flex-start;
  max-height: 0px;
  overflow-y: visible;
  font-weight: 700;
  text-align: center;
}

.dropdown {
  text-align: center;
}

.content {
  @include light-scrollbar;
  display: flex;
  flex-direction: row;
}

.graph {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-right: 2rem;
}

.submenu {
  min-width: 64%;
}

.rename-textbox {
  height: 2.5rem;

  > * {
    height: 100%;
  }

  .succeeded {
    background-color: #66cc33;
  }
  .error {
    background-color: #db2828;
  }
}
