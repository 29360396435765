.iconSpec{
    margin-top: 32px;
    margin-bottom: 8px;
    margin-left: 8px;
    .icon{
        height: 20px;
        width: 20px;
    }
}
.text {
    margin-left: 8px;
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
}
