@import 'src/variables.scss';

.card:global(.ui.card) {
  margin-left: auto;
  width: 205px;
  font-size: 14px;
  margin-right: 8px;
  .description {
    line-height: 30px;
    .header {
      padding: 10px;
      text-align: center;
      .count {
        font-weight: bold;
        color: #70bf44;
        font-size: 56px;
        padding: 5px 0px;
        position: relative;
        top: 1px;
      }
      .label {
        padding-top: 10px;
      }
    }
    .content {
      .section {
        border-top: solid 1px $light-grey;
        padding-top: 6px;
      }
      .metric {
        display: flex;
        align-items: center;
        padding-bottom: 6px;
        .label {
          &.link {
            cursor: pointer;
            color: $primary;
          }
          .count {
            font-weight: bold;
          }
          position: relative;
          line-height: 20px;
          padding-left: 16px;
          width: 88%;
        }
      }
    }
  }
  .green {
    color: #70bf44;
  }
}
