.podsTable {
  margin-top: 42px;
  clear: right;
  .trialText {
    color: #f0712c;
    &.expiringSoon {
      color: #ff3366;
    }
    .header {
      font-size: 11.5px;
      font-weight: bold;
      margin-left: 20px;
      margin-bottom: -4px;
    }
  }
}
