.parent {
  display: flex;
  margin-top: 40px;
}
.leftCol {
  display: flex;
  flex-direction: column;
}

.rightCol {
  display: flex;
  flex: 1;;
  flex-direction: column;
}

.cell {
  flex: 1
}

.segment {
  margin-top: 0px;
  margin-right: 15px;
}