@import 'src/variables.scss';

.modalOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  z-index: $zIndexHigh;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.modalContent {
  border-radius: 4px;
  position: absolute;
  width: 350px;
  z-index: $zIndexHigh;
  background: white;
  text-align: center;
  padding: 24px 16px 16px 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $text !important; // overriding semantic important
  .header {
    overflow-wrap: break-word;
    font-size: 20px;
    font-weight: 900;
    padding-bottom: 22px;
    margin-bottom: 0;
  }
  .modalInfo {
    text-align: left;
  }
  .error {
    margin-top: 24px;
    color: red;
    font-weight: bold;
    text-align: center;
  }
  .actionButtons {
    float: right;
    margin-top: 24px;
  }
}
