.wrapper {
  display: flex;
  justify-content: stretch;
  align-items: stretch;

  & > * {
    min-height: 300px;
    flex: 1;
  }
}
