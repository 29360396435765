@import 'src/variables.scss';
@import 'src/_mixins';

.wrapper {
  * {
    @include light-scrollbar;
  }
}

.static {
  width: 325px;
}

:global(.ui.dropdown .menu .item).fixed {
  width: 250px;
  font-size: 16px;
  height: 55px;
  font-weight: bold;
  line-height: 24px;
  border-top: $grey 1px solid;
}

.tableViewUnopened:global(.ui.input > input) {
  border: none;
  background: transparent;
  margin-right: 10px;
}

.tableViewUnopenedError:global(.ui.input > input)::placeholder {
  color: #9f3a38;
}

.solSearch {
  max-width: 350px;
  display: inline-block;
  max-height: 50px;
  height: 100%;
  position: relative !important;
  margin: 0;
  padding: 0;
  bottom: 5px;
}

.solSearchTable {
  width: 250px;
  display: inline-block;
  max-height: 50px;
  height: 100%;
  position: relative !important;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.searchInput {
  margin: 0;
  padding: 0;

  .static {
    padding-top: 4px;
  }
}

.searchDropdownIcon {
  top: 24px;
  position: absolute !important;
  bottom: -10%;
  right: -1px;
  width: 10% !important;
  height: 100%;
  border: 1px transparent solid !important;

  :global(.dropdown.icon) {
    border: 1px transparent solid !important;
    padding: 13px !important;
    padding-right: 16px !important;
    transform: scale(1.15);
  }
}

.tableIcon {
  top: 2px;
  position: absolute !important;
  right: -1px;
  width: 10% !important;
  height: 100%;
  bottom: 45%;
  border: 1px transparent solid !important;

  :global(.dropdown.icon) {
    border: 1px transparent solid !important;
    padding: 10px !important; // all importants needed to override semantic
    color: $primary;
  }
}

.questionIcon {
  position: relative;
  bottom: -3px;
}

.editIcon {
  position: relative;
  top: 15px;
  color: $primary;
}

:global(.ui.dropdown .menu).firstMenu {
  top: 38px;
  border: 1px #5ba9e6 solid !important;
  border-top: 1px transparent !important;
  max-height: 250px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: $zIndexHigher;
  max-width: 275px;
  min-width: 275px;
  padding-right: 4px;
}

:global(.ui.dropdown .menu).firstMenuNew {
  top: 38px;
  border: 1px #5ba9e6 solid !important;
  border-top: 1px transparent !important;
  max-height: 250px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: $zIndexHigher;
  max-width: 318px;
  min-width: 318px;
  padding-right: 4px;
}

:global(.ui.dropdown .menu).firstMenuTable {
  border: 1px #5ba9e6 solid !important;
  border-top: 1px transparent !important;
  max-height: 250px !important;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: $zIndexHigher;
  margin-top: -2px;
  max-width: 250px;
  min-width: 250px;
  padding-right: 4px;
}

:global(.ui.dropdown .menu).nestedMenu {
  width: 90% !important;
  max-height: 200px !important;
  border: 1px transparent !important;
  z-index: $zIndexHigher;
}

.label {
  display: block;
  font-size: 15px;
  font-weight: bold;
}

:global(.dropdown .menu > .header).dropdownMenu {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 1px;
  margin-bottom: -5px;
  margin-top: 0px;
  z-index: $zIndexHigher;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

:global(.dropdown .menu > .header).dropdownMenu:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}

:global(.dropdown .menu > .header).disabledElement:hover {
  cursor: default !important;
  background-color: white;
}

:global(.dropdown .scrolling.menu .item).dropdownDescription {
  font-size: 12px;
  font-weight: normal;
  margin-top: 0px;
  padding-left: 0px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 270px !important;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (min-width: $md-min) and (max-width: $md-max) {
    width: 170px;
  }

  @media screen and (max-width: $sm-max) {
    width: 150px;
  }
}

:global(.dropdown .menu > .header).dropdownMenu>div.headerText {
  width: 100%;
  max-width: 340px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0px;
  margin: 0px;
}

.pencil {
  position: absolute;
  margin: 0px;
  top: 5%;
  left: 80%;
  z-index: $zIndexMedium;
}

.delete {
  position: absolute;
  margin: 0;
  left: 90%;
  top: 5%;
  z-index: $zIndexPriority;
}

.pencil:hover {
  cursor: pointer;
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.delete:hover {
  cursor: pointer;
}

.addIcon {
  position: absolute;
  margin: 0px;
  top: 18%;
  left: 88%;
  z-index: $zIndexMedium;
  cursor: pointer !important;
}

:global(.ui.dropdown .menu .item).pinnedButton {
  font-size: 16px;
  height: 55px;
  font-weight: bold;
  line-height: 30px;
  width: inherit;
  max-width: 350px;
  padding-top: 5px !important; // Overriding semantic important
  border-top: 1px solid $grey;

  @media screen and (min-width: $lg-min) and (max-width: $lg-max) {
    width: 200px;
  }

  @media screen and (min-width: $md-min) and (max-width: $md-max) {
    width: 200px;
  }

  @media screen and (max-width: $sm-max) {
    width: 175px;
  }
}

:global(.ui.dropdown .menu .item).staticPinnedButton {
  font-size: 16px;
  height: 55px;
  font-weight: bold;
  line-height: 24px;
  border-top: $grey 1px solid;
  width: 315px !important;
}

:global(.ui.dropdown .menu .item).fixedPinButton {
  font-size: 16px;
  height: 55px;
  font-weight: bold;
  line-height: 24px;
  border-top: $grey 1px solid;
  width: 250px !important; //overridin semantic width
}

.bulkDropDown:global(.ui.dropdown) {
  @include bulk-dropdown;

  .pencil {
    top: 5%;
  }

  .delete {
    top: 5%;
  }

}
