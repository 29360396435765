@mixin emphasizeColumns() {
  $bg-odd: #e9f3fa;
  $bg-even: #e4eef6;
  $border: 1px solid #2987CD;

  tr {

    .emphasize {
      &.start {
        border-left: $border;
      }

      &.end {
        border-right: $border;
      }
    }

    &:nth-child(odd) {
      .emphasize {
        background-color: $bg-odd;
      }
    }

    &:nth-child(even) {
      .emphasize {
        background-color: $bg-even;
      }
    }

    &:last-child {
      td.emphasize {
        border-bottom: $border;
      }
    }

  }

  thead > tr > th.emphasize,
  &.sortable thead th.sorted.emphasize {
    border-top: $border;
    background: $bg-odd;
  }


}
