.cards {
  margin: 0 auto;

  width: 99%;
  @media screen and (min-width: 740px) {
    max-width: 732px;
    &:global(.react-multi-carousel-track) {
      max-width: 100%;
    }
  }
  @media screen and (min-width: 1390px) {
    max-width: 1098px;
  }
}

.top-row {
  min-height: 421px;
  :global(.ui.card) {
    min-width: 350px;
    margin: auto;
    margin-top: 4px;
    :global(.content) {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 26px;
      padding-bottom: 18px;
    }
  }
  max-width: 1440px;
  margin: auto;
  :global(.legend) {
    max-height: 100%;
  }
}

.top-row {
  &:global(.react-multi-carousel-list) {
    display: block;
  }

  :global(.react-multi-carousel-track) {
    float: left;
  }

  :global(.arrow-btn) {
    top: 44%;
    opacity: 0;
  }

  &:hover {
    :global(.arrow-btn) {
      opacity: 1;
    }
  }
}
