@import 'src/variables.scss';

.header {
  padding: 16px;
  font-size: 20px;

  .grey {
    color: grey;
  }

  i {
    margin: 0 10px;
  }

  .helpTooltip {
    margin-left: -5px;

    .questionIcon {
      height: 24px;
      width: 24px;
      position: relative;
      right: -8px;
      top: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}


.cardHeader {
    margin: 5px;
    width: 98%;
    max-height: 60px;
    display: inline-flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 1em;

    .leftContent {
        display: inline-flex;
        justify-content: flex-start;
        min-width: 33%;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
        margin-right: 8px;
        padding-top: 7px;
        padding-left: 7px;
    }

    .rightContent {
        display: inline-flex;
        justify-content: flex-end;
        min-width: 66%;

        .actionButtons {
        display: flex;
        }
    }


  .cardHeader:after {
    padding: 0px;
    margin: 1px;
    content: " ";
    display: block;
    position: absolute;
    background: $light-grey;

    height: 2px;
    width: 96%;
    top: 9%;
  }
}


