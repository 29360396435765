$primary: #1279c6;
$secondary: #e0e1e2;
$success: #66cc33;
$error: #bf1244;
$warning: #fbb812;
$text: #1b1c1d;
$nav-primary: #17c7f4;
$nav-secondary: #2b2e3d;
$light-grey: #e5e5e5;
$grey: #c8c8c8;
$dark-grey: #9e9e9e;
$darker-grey: #575757;
$blue: #1279c6;
$mersive-green: #66CC33;
$element-grey: #A8A8A8;

$element-blue: #2987cd;

$base-font-size: 14px;
$black: $text;
$white: $secondary;
$light-blue: $nav-primary;
$dark-blue: $primary;
$red: $error;
$zIndexLowest: 100;
$zIndexLower: 300;
$zIndexLow: 400;
$zIndexMedium: 500;
$zIndexHigh: 600;
$zIndexHigher: 700;
$zIndexHighest: 1000;
$zIndexPriority: 1001;

$extra-large-breakpoint: 1281px;
$large-breakpoint: 993px;
$medium-breakpoint: 769px;

$xl-min: 1281px;
$lg-max: 1280px;
$lg-min: 993px;
$md-max: 992px;
$md-min: 769px;
$sm-max: 768px;
