.parent {
  position: relative;
}
.helpButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.segmentDiv {
  position: absolute;
  top: 3.5rem;
  right: 1rem;
  min-width: 200px;
}
.segment {
  cursor: pointer;
}
