@import 'src/variables.scss';

:global(.ui.card .dimmer).loadingMaps {
  z-index: $zIndexHigh;
}
.modalContent {
  min-height: 260px;
  .questionIcon {
    position: relative;
    bottom: -3px;
  }
  .header {
    font-size: 20px;
    font-weight: 900;
    padding-bottom: 22px;
    margin-bottom: 0;
    color: $text !important; // overriding red error from semantic
  }
  .subHeader {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0;
  }
  .subHeaderContent {
    margin-top: 0px;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 14px;
  }
  .googlePlaceLabel {
    font-weight: 700;
    font-size: 15px;
    display: block;
    padding-bottom: 3px;
  }
  .firstInput {
    padding: 0px 0px 16px 0;
  }
  .googlePlaceInput {
    width: 100%;
    &.fluid {
      padding-right: 0;
    }
  }

  :global(.ui.error.input) {
    padding-bottom: 5px;
  }
  .errorLabel {
    color: #9f3a38;
  }
  .errorText {
    height: 16px;
    padding-left: 15px;
    margin-bottom: 10px;
    color: #9f3a38;
    font-size: 12px;
    font-weight: 500;
  }

  .ClearIcon {
    color: $grey;
    cursor: pointer;
    &:hover {
      color: $dark-grey;
    }
  }
}
