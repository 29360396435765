.wrapper {
    text-align: center;    
    margin: 56px;
}

.text {
    font-family: Lato;
    font-size: 18px;
    font-style: italic;
    font-weight: 300;
    line-height: 22px;
    margin: 15px auto;
    width: 232px;
}
