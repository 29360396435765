@import 'src/variables.scss';
.content {
  background-color: #ffffff;
  border-radius: 4px;
  border-left: 2px solid #1d7ac9;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin: 16px 0;
  min-height: 62px;
  min-width: calc(100% - 32px);
  padding: 5px 10px;
  position: relative;
  &.disabled {
    border-left-color: $dark-grey;
    :global(.title) {
      cursor: default;
    }
  }

  :global(.title.active).titleBar {
    min-height: 40px;
    &.static {
      cursor: default;
    }
  }

  :global(.title) {
    margin-top: 6px;
    .title {
      min-height: 60px;
      font-size: 16px;
      font-weight: bold;
      padding: 15px 0;
    }
    .actionItems {
      float: right;
      padding: -5 16px;
      display: flex;
      button {
        margin-top: -15px;
        font-size: 16px;
      }
    }
  }
  .iconWrapper {
    float: right;
    padding-right: 5px;
  }

  .accordionIcon {
    transition: transform 0.2s ease;
    position: relative;
    z-index: $zIndexLow;
  }

  :global(.active) .accordionIcon {
    transform: rotate(-180deg);
  }

  .accordionContent {
    padding-bottom: 20px !important;
    border-top: 1px solid #e5e5e5;
    margin-top: 10px !important;
    border-top: 1px solid #e5e5e5;
    margin-right: 11px;
    padding-top: 32px !important;
  }
}
