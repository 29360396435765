@import 'src/variables.scss';
.numWrapper {
  display: inline-block;
  margin-right: 8px;
  .inputGroup {
    font-weight: 700;
    display: block;
    font-size: 13px;
  }
  .inputDisabled {
    opacity: .45;
    font-weight: 700;
    font-size: 13px;
    display: block;
  }
  .note {
  margin-top: 0.25em;
  font-size: 12px;
  font-weight: 300;
}
  :global(.flatpickr-input) {
    border-radius: 4px;
    border: 1px solid $grey;
    height: 38px;
    margin: 4px;
    max-width: 88px;
    padding: 4px;
    &:global(.active) {
      border-color: $primary;
    }
  }
  .time {
    height: 38px;
    width: 100%;
    border: 1px solid #C8C8C8;
    border-radius: 4px;
    text-align: center;
    margin-left: 0px;
  }
  .time:active,
  .time:focus {
    border: 1px solid $primary;
    border-radius: 4px;
  }
  .time:disabled,
  .time:disabled:hover,
  .time:disabled:active {
    cursor: default;
    opacity: .45;
    border: 1px solid $grey;
  }
}
.syncStateIcon {
  margin-left: 3px;
}