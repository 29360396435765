.reenterDashboardPassword {
  .link {
    cursor: pointer;
  }
  margin-top: -8px;
  margin-bottom: 16px;
}

.modalPassword {
  margin-top: 24px;
}
