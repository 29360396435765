pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.podGridSvg {
  max-width: 100%;
  max-height: 100%;
}

.header {
  padding: 16px 0;
  font-size: 22px;
  font-weight: 700;
  .icon {
    margin-left: 5px;
  }
}
.subHeader {
  font-size: 16px;
  margin-bottom: 16px;
}

.buttonWrapper {
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
}

.visible.menu.transition {
  background-color: 'pink';
  overflow: visible;
}

.buttonHolder{
  display:flex;
  justify-content: space-between;
}
.nextButton {
  margin-top: 16px !important;
}
.backButton {
  margin-top: 16px !important;
}
