.header-container {
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  :global(.ui.header).header {
    font-size: 20px;
    margin-bottom: 0;
  }

  :global(.ui.header).detail-header {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.close-selected {
  display: flex;
  padding: 0;
  background: transparent;
  border: none;
  color: #e0e1e2;
  font-size: 10px;
  margin-left: 7px;
}

.list-container {
  width: 189px;

  .list {
    padding-left: 0;
    list-style: none;

    .pod {
      margin-bottom: 9px;
      font-size: 12px;
      line-height: 15px;

      & .podNumber {
        color: rgba(0, 0, 0, 0.87);
      }

      & .podButton {
        color: #1279c6;
        background-color: transparent;
        border: 0;
        display: inline;
        cursor: pointer;
        padding: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        font-family: Lato;
        text-align: left;
      }
    }
  }
}

.detail-list {
  list-style: none;
  padding-left: 0;
  font-size: 12px;
  line-height: 18px;
}

.detail-name {
  font-weight: bold;
}

.italic {
  font-style: italic;
}