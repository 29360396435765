.almostPage {
  padding: 10px;
  width: 560px;
  margin: auto;
}

.wrapper {
  width: 560px;
  max-height: 400px;
  position: relative;
  overflow-y: auto;
}

#podTableID>tbody>tr>td:nth-child(2) {
  color: #767676;
}