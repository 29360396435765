@import 'src/_mixins';
@import 'src/variables.scss';

:global(.ui.card).solCard {
  border: 1px #f8f8f8 solid;
  border-radius: 4px;
  margin: 0px;
  margin-bottom: 16px;
  width: 100%;
  min-height: 117px;
  z-index: $zIndexMedium +1;
  padding: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  * {
    @include light-scrollbar;
  }

  > :global(.content) {
    padding: 16px;
    padding-bottom: 8px;
    min-height: 117px;
    display: flex;
    justify-content: space-between;

    &:after {
      content: none;
    }

    @media screen and (max-width: 1400px) {
      height: 100%;
    }

    > :global(.header) {
      color: #000000;
      font-family: Lato;
      font-weight: 700;
    }
  }

  .header {
    display: auto;
    margin: 0px;
    margin-bottom: 8px;

    :global(.sub.header) {
      color: black;
      font-size: 14px !important; //ui  override semtatic
      padding-top: 0px;
      font-weight: normal;
    }
  }

  .filters {
    display: inline;
  }

  .filterby {
    font-weight: bold;
  }

  .topPillsContainer {
    margin: 0px;
    display: block;

    [class*='circular'] {
      margin-bottom: 0.3em;
    }

    >div {
      width: 65%;
    }

    label {
      font-size: 16px;
      line-height: 19px;
      width: 100px;
      margin-bottom: 0.3em;
    }

    @media (max-width: $lg-max) {
      flex-direction: column;
    }

    .button {
      width: 100px !important;
      float: left;
      margin: 0px;
      margin-right: 8px;
    }

    .pills {

      float: right;
      width: calc(100% - 108px);
      padding-top: 5px;
      margin: 0px;

      .appliedFilters {
        float: left;
      }

      .customFilter {
        padding-right: 4px;
        float: left;
      }
    }

    .button {
      width: 100px !important;
      float: left;
      margin: 0px;
      margin-right: 8px;
    }

    .filterButton {
      border: 2px solid #1279c6;
      color: #1279c6;
      background-color: white;
      font-size: 15px;
      padding: 8px;
      padding-bottom: 7px;
      width: 100px;
      margin: 0px;

      span {
        position: relative;
        top: -3px;
        left: 0px;
      }
    }

    &.shadow {
      -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }

    .dateRange {
      max-width: 500px;
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 16px;
    }
  }
}
