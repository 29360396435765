.confirmationCard {
  height: 200px;
  width: 560px;
  border: 2px solid #2987CD;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  padding: 25px 30px;
  margin-bottom: 20px;
}

.confirmationLabel {
  color: #1B1C1D;
  font-family: Lato;
  font-size: 22px;
  font-weight: 900;
  line-height: 27px;
  width: 100%;
  padding-bottom: 22px;
}

.confirmationDetails {
  font-size: 18px;
  font-weight: normal;
}

.confirmationHighlighted {
  color: #2987CD;
}

.confirmationDisclaimer {
  font-style: italic;
  font-size: 12px;
  line-height: 0em;
}