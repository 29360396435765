.header {
  padding: 16px 0;
  font-size: 22px;
  font-weight: 700;
  .icon {
    margin-left: 5px;
  }
  .grey {
    color: grey;
  }
}
.subHeader {
  display:flex;
  font-size: 18px;
  font-weight: 700;
}

.rowGroup {
  display: flex;
  flex-direction: row;
  margin-left: 48px;
}

.cancelSave {
  display:flex;
  margin: -6px;
  padding-right: 85px;
}

.splitter {
  display:flex;
  justify-content: space-between;
  padding-top: 18px;
  margin-left: 28px;
}

.settingsCard {
  min-height: 680px;
  height: 680px;
}