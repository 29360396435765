@import 'src/variables.scss';
.buttons {
  display: flex;
  margin-top: 2px;
  i {
    margin: 0 10px;
  }
}
.templateEllipses {
  float: right;
}

.hidden {
  display: none;
}
.locked {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #5c5d5d;
  opacity: 0.4;
  z-index: 350;
  cursor: pointer;
}
:global(.ui.primary.button).editButton {
  position: relative;
  z-index: $zIndexMedium;
}
.deleteModalText {
  overflow: hidden;
  text-overflow: ellipsis;
}
