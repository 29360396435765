@import 'src/variables';

.categoryDropdown {
  &:global(.ui.selection.dropdown) {
    padding-left: 4px;
    padding-right: 0;
  }
  &.unassigned > :global(.text) {
    font-style: italic;
    color: #767676;
  }
}
