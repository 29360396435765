.parent {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 300px;
  width: 100%;
}

.first-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;
  :global(.ui.header) {
    padding-top: 16px;
    padding-left: 16px;
  }
}

.top {
  flex-direction: column;
  display: flex;
  align-self: flex-end;
  padding-top: 15px;
  padding-right: 15px;
}

.bottom {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.elementMessageContainer {
  margin-right: 12px;
}
