@mixin center-vertical {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin light-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #e5e5e5 transparent;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 4px;
    box-shadow: inset 0 0 4px #e5e5e5;
  }
}

@mixin bulk-dropdown {
  :global(.scrolling.menu.transition > div.header:first-child) {
    text-transform: none;
    border-radius: 2px 2px 0 0;
    padding: 8px 4px 8px 16px;
    margin: 0;
    color: white;
    background-color: $primary;
    :global(div.item) {
      margin-bottom: 0;
      display: flex;
      width: 100%;
      font-size: 14px;
      div.podsSelected {
        flex-grow: 1;
        flex-shrink: 1;
        font-weight: bold;
      }
      i {
        cursor: pointer;
        color: white;
      }
    }
  }
}
