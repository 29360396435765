@import 'src/variables.scss';


.updateNotification {
  width: 360px;

  .cardContent {
    display: flex;
    flex-grow: 1;
    font-size: 16px;

    .refreshIcon {
      cursor: pointer;
      fill: $primary;
      width: 20px;
      margin-right: 8px;
    }
  }
}

.exclamationTriangle {
  color: $warning;
  position: relative;
  top: 4px;
  margin-right: 8px;
}

.header {
  margin-top: 12px;
  font-size: 20px;

  .grey {
    color: grey;
  }

  i {
    margin: 0 10px;
  }
}

.prevNext {
  margin-top: 32px;
  display: flex;

  :global(.ui.button) {
    padding: 0;
    margin-right: 0;

    i,
    strong {
      vertical-align: middle;
      color: $text;
    }
  }

  .prev {
    strong {
      color: $text;
      margin-left: -0.5em;
    }
  }

  .next {
    margin-left: auto;

    strong {
      color: $text;
      margin-right: -0.5em;
    }
  }
}

.templates {
  .headerRow {
    display: flex;
    justify-content: space-between;

    h2.templatesHeader {
      font-size: 22px;
      margin-top: 16px;
      margin-bottom: 4px;
    }
  }

  .unmanageableWarning {
    margin-bottom: 8px;

    i {
      margin-right: 8px;
    }

    font-size: 16px;
    font-style: italic;
    font-weight: 300;
  }

  .unmanageable {
    .templateIcon {
      fill: $dark-grey;
    }

    h3 {
      color: $dark-grey;
    }
  }
}

