:global(.ui.card).changePasswordCard {
    width: 100%;
    margin: 0;
}

.passwordInput {
    display: block;
}

.cardContent {
    padding: 1em
}