@import "src/_variables.scss";
.templateWrapper {
  margin: 0 16px 16px 16px;
  .addNewRss {
    margin-bottom: 1px;
  }
  .addRss {
    display: block;
    .enableRss {
      display: inline-block;
      :global(.ui.toggle.checkbox input:checked ~ label:before) {
        background-color: #66cc33 !important; // need important to override a semantic important
      }
    }
    .duration {
      display: inline-block;
      width: 94px;
      .numInput {
        width: 86px;
      }
    }
    .rssRow {
      display: inline-block;
    }
    .customMessage {
      display: inline-block;
      vertical-align: top;
      .deleteRss:hover {
        cursor: pointer;
      }
    }
    .customMessageField {
      width: 288px;
      padding-left: 15px;
      font-style: italic;
      display: inline-block;
    }
  }
  .emergencyTextArea {
    margin-left: 29px;
  }
  .header {
    display: block;
    height: 25px;
    margin-top: 16px;
    .columnOne {
      width: 86px;
      margin-left: -8px;
      display: inline-block;
    }
    .columnTwo {
      width: 287px;
      margin-left: -5px;
      display: inline-block;
    }
    .columnThree {
      width: 94px;
      display: inline-block;
    }
    .columnFour {
      display: inline-block;
    }
  }
  .spanMargin {
    margin-top: 20px;
  }
}

@media (max-width: 1153px) {
  .templateWrapper {
    .addNewRss {
      margin-bottom: 16px;
    }
    .header {
      margin-left: 26px;
      .columnFour {
        display: none;
      }
    }
    .addRss {
      display: block;
      margin-left: 28px;
      .rssRow {
        display: inline-block;
      }
      .customMessage {
        display: inline-block;
        vertical-align: top;
        .deleteRss:hover {
          cursor: pointer;
        }
      }
      .customMessageField {
        width: 288px;
        padding-left: 15px;
        font-style: italic;
        display: inline-block;
      }
    }
    .emergencyTextArea {
      margin-left: 29px;
    }
  }
}
