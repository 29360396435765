.rooms-in-use:global(.ui.card) {
  width: 392px;
  height: 274px;

  .content {
    display: flex;
    align-items: center;

    & .contentLeft {
      text-align: center;
      padding: 24px 24px 24px 8px;
      border-right: 1px solid #eaeaea;
      flex: 1;

      .in-use-number {
        font-size: 75px;
        font-style: normal;
        font-weight: 400;
        line-height: 90px;
        letter-spacing: 0px;
        color: #70bf44;
      }

      .reduced-font {
        font-size: 55px;
      }

      .in-use-legend {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        max-width: 97px;
        margin: 0 auto;
      }

      .capacity {
        margin-top: 24px;
        width: 148px;

        :global(.progress) {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
        }
      }
    }

    & .contentRight {
      padding: 24px 0 24px 10px;
      flex: 1;

      .row {
        display: flex;
        align-items: center;
        padding: 0;
        background: none;
        border: none;
        text-align: left;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 22px;
        }

        .number {
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px;
          letter-spacing: 0px;
          color: #70bf44;
          width: 75px;
          margin-right: 16px;
          text-align: center;
        }

        .label {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0px;
          color: #1279c6;
          flex: 1;
        }
      }
    }
  }
}