@import 'src/variables.scss';

.LocationPage {
  margin-bottom: 32px;

  .header {
    margin: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    .pill {
      display: inline;
    }
  }

  .tabs {
    margin-bottom: -16px;
    float: right;
  }
}
