#header {
  margin-bottom: 0px;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
  background-color: #2b2e3d;
  #logoWrapper {
    padding: 19px;
    #image {
      width: 5.5em;
      //TODO: width 7.5 em when solstice cloud goes live
    }
  }
}

.errorBoundary {
  margin: 5% auto 0 auto;
  max-width: 600px;
}