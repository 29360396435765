@import 'src/_variables.scss';
.wifiWrapper {
  margin-left: 16px;
  .enabledWifiWrapper {
    margin-left: 28px;
    .radioButtonsGroup {
      display: block;
      .singleRadio {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
}
.staticIpTooltip:global(.ui.top.left.popup.transition.visible) {
  margin-left: 105px;
}
