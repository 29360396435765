@import 'src/variables.scss';

.sideFilterMenu {
  padding: 0px;
  margin: 0px;
  width: 400px;

  .header {
    font-size: 26px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;

  }

  .filterMenuIcon {
    margin-left: 0.25rem;
    margin-right: 0px;
  }

  .clearButton {
    width: 250px;
    background-color: #E0E1E2;
    color: #676868;
    margin: 10px 75px;
    text-align: center;
  }

  :global(i.dropdown.icon) {
    display: none !important; // need to override the semtantic UI Icon to have out own dropdown icon 
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 0px auto;
    padding: 10px;
    padding-left: 0px;

    .titleText {
      display: inline-block;
      max-width: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      float: right;
    }

    .options {
      border: blue 2px solid;
      float: right;
    }
  }

  .line {
    background-color: #e5e5e5e5;
  }

  .content {

    .linkToggle {
      float: right;
      position: relative;
      top: -40px;
      left: -40px;
    }

    .option {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: left;
      align-content: center;
      grid-column-gap: 0.25em;

      .name {
        position: relative;
        top: -3px;
        left: 5px;
      }
    }

    .optionDisabled {
      color: gainsboro;

      .name {
        position: relative;
        top: -3px;
        left: 5px;
      }
    }
  }

}

.optionGrid {
  width: 100%;
  display: grid;
  grid-row-gap: 0.75em;
}

.unassigned {
  font-weight: bold;
}

.optionDisplayName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.categories {
  padding-bottom: 4px;

  .category:global(.ui.item) {
    display: flex;

    * {
      flex-shrink: 0;
    }

    :global(.text) {
      flex-shrink: 1;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    font-size: 14px;
    color: #3a3a3a;
    padding: 8px 16px;
  }
}