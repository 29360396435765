.card {
  flex: 1;
}

.card-content {
  display: flex;
  flex: 1;
}

.daily-active-chart {
  display: flex;
}

.header {
  font-size: 20px;
  margin-bottom: 16px;
  margin-top: 0;
  .circleIcon {
    padding-top: 0.5px;
    position: relative;
    top: 3px;
    left: 5px;
  }
}

.subheading {
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.chart {
  flex: 1;
}

.chart-container {
  min-height: 256px;
  display: flex;
  flex-direction: column;
  flex: 1;

  :global(.recharts-tooltip-wrapper) {
    text-transform: capitalize;
  }
}

.single-result {
  border-left: 1px solid #e5e5e5;
  padding-left: 32px;
  margin-left: 32px;
}
