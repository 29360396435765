@import 'src/variables.scss';

.progressComponent {
  height: 4px;
  width: 100%;
}

.progressComponent.visible {
  position: relative;
  display: block;
  background-color: #99cfe1;
  /* border-radius: 2px; */
  background-clip: padding-box;
  /* margin: 0.5rem 0 1rem 0; */
  overflow: hidden;
}

.progressComponent.header {
  position: fixed;
  height: 4px;
  top: 57px;
  right: 0px;
  z-index: $zIndexHigh;
}
.progressComponent.header.oneOpen {
  top: 107px;
}

.progressComponent.header.twoOpen {
  top: 157px;
}

.progressComponent.header.threeOpen {
  top: 207px;
}

.progressComponent .determinate {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #0088b5;
  transition: width 0.3s linear;
}

.progressComponent .indeterminate {
  background-color: #0088b5;
}

.progressComponent .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395)
    infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progressComponent .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1)
    infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
