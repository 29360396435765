.alerts-chart {
  height: 100%;
}

.segment {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.header {
  font-size: 24px;
  padding: 15px;
  padding-bottom: 5px;
}

.body {
  display: flex;
  flex-direction: column;
  height: 400px;
  flex: 1;
}

.subheading {
  font-size: 12px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.chart {
  flex: 1;
}

.link {
  text-align: right;
}
