@import 'src/variables.scss';

:global(.ui.grid).columnWrapper {
  margin-left: 16px;
  max-width: 750px;
  .rowOne{
    padding-bottom: 0px;
  }
  .rowTwo{
    padding-top: 0px;
  }
  .columnOne {
    border-right: 1px solid #E5E5E5;
    padding-left: 32px;
    .header {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 0px
    }
    .week {
      margin-bottom: 16px;
      .weekLayout {
        display: inline;
        line-height: 13px;
        font-size: 11px;
        margin-bottom: 116px;
        .daysActive {
          font-weight: bold;
        }
        .daysInactive {
          color: #C8C8C8;
        }
      }
    }
    .allDay {
      margin-top: 5px;
    }
    .timePicker {
      display: inline-block;
    }
  }
  .secondColumn {
    padding-left: 50px;
    .header {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 0px;
    }

    .week {
      margin-bottom: 16px;
      .weekLayout {
        display: inline;
        line-height: 13px;
        font-size: 11px;
        margin-bottom: 16px;
        .daysActive {
          font-weight: bold;
        }
        .daysInactive {
          color: #C8C8C8;
        }
      }
    }
    .allDay {
      margin-top: 5px;
    }
    .timePicker {
      display: inline-block;
    }
  }
  .suspendOptionsWrapper {
    margin-left: 16px;
    .suspendOptions {
      .suspendRadio {
        margin-left: 16px;
      }
      .suspendInputs {
        margin-left: 40px;
      }
    }
  }

  .modeWrapper {
    margin-left: 16px;
    margin-bottom: -10px;
    .occupancyHelpText {
      color: #181C1D;
      font-weight: 300;
      font-size: 12px;
      max-width: 300px;
      margin-top:-10px;
    }
    .delayOptions {
      margin-bottom: -10px;
    }
  }
}
