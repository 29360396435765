@import 'src/_mixins';
@import 'src/variables.scss';

$width: 154px;

.wrapper {
  min-width: $width;
  max-width: $width;
  * {
    @include light-scrollbar;
  }
}
.floorsTableDropdown {
  padding-left: 15px;
  i {
    color: $primary;
    padding-right: 10px;
    padding-top: 6px;
  }
}

.floorLabel {
  font-weight: bold;
  font-size: 16px;
  display: block;
}

.floorsTableDropdownSingle {
  padding-left: 14px;
  border-radius: 4px;
  height: 38px;
  border: 1px solid $light-grey !important;
  width: $width;
  padding-top: 9px;
  margin-top: 2px;
  i {
    padding-right: 15px;
    padding-top: 4px;
  }
}

:global(.ui.dropdown .menu).menuWrapper {
  margin-bottom: 16px;
  border: 1px solid #5ba9e6 !important;
  border-radius: 4px;
  margin-top: -27px;
}

:global(.ui.dropdown .menu).menuWrapperSingle {
  margin-bottom: 16px;
  border: 1px solid #5ba9e6 !important;
  border-radius: 4px;
  margin-top: -37px;
}
:global(.ui.dropdown .menu .item).addFloorButton {
  font-size: 16px;
  height: 40px;
  font-weight: bold;
  border-top: $grey 1px solid;
  width: $width;
}

:global(.ui.dropdown .menu .item).floorMenuItem {
  font-size: 16px;
  height: 40px;
  width: $width;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

:global(.ui.dropdown .scrolling.menu).mainMenu {
  max-height: 200px;
  border-top: none !important; // overriding semantic
  border-radius: 4px !important;
  .bulkIcon {
    padding-top: 0px;
  }
}

.floorsWrapper {
  margin-bottom: -16px;
  margin-top: 2px;
}

.bulkDropDown:global(.ui.dropdown) {
  @include bulk-dropdown;
  }
