@import 'src/variables';

.metricCards {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  :global(.ui.card) {
    margin-top: 0px;
    width: calc(20% - 8px);
    @media (max-width: $lg-max) {
      margin-top: 16px;
      width: calc((100% / 3) - 6px);
    }
    &:first-child {
      width: 40%;
      @media (max-width: $lg-max) {
        width: 100%;
      }
    }
  }
}
