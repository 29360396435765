.wrapper {
  width: 560px;
  max-height: 400px;
  position: relative;
  overflow-y: auto;
}

.accordianTitle {
  font-size: 18px;
  font-weight: 400;
  padding-right: 11px;
}

.active>.accordianIcon {
  transform: rotate(-180deg);
}

.accordianIcon {
  transition: transform .2s ease;
}

.accordianEdit {
  float: right;
  cursor: pointer;
}