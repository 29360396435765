@import "src/variables.scss";

:global(.ui.form) {
    :global(.fields) {
      .input:global(.field) {
        margin: 0 0 1.5rem;
        min-width: 24rem;
        width: 350px;
        padding: 0;
      }
    }
    .group {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: left;
    }
}
.createOrgWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    background-image: url(../../assets/cloud_login_desktop.png);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    @media (max-width: $md-min) {
        background-image: url(../../assets/cloud_login_small.png);
}
.createOrgLogo {
    height: 100px;
    width: 350px;
    margin-bottom: 32px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.createOrgContainer {
    padding: 60px;
    min-height: 376px;
    width: 500px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    .joinNow {
    margin-top: 30px;
    }
}
.inputText {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}
}
.error {
    color: #A1403E;
    width: 80%;
    text-align: left;
    margin-top: -10px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    }
.hidden {
    display: none;
}
.back{
    width: 360px;
    margin-bottom: 10px;
}
.buttonLink {
    background: transparent;
    border: 0;
    padding: 0;
    color: #4183c4;
    cursor: pointer;
    font-family: "Lato";
}
.loginButton {
    width: 350px;
    margin-bottom: 18px;
    > div {
      width: 100%;
      > button {
        width: 100%;
        margin-bottom: 18px;
      }
    }
}
.copyright{
    color:rgba(200,200,200,.90);
    
}
.popup{
    margin-left: 10px;
}
.popupBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    margin: 10px -10px 0 10px;
    width: 250px;
    height: 160px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 4px;
}
.list{
    padding-left: 15px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.passwordText{
    margin-left: -10px;
    margin-bottom: -10px;
}
.link{
    text-decoration: none;
}
.asterisk {
    color: red;
}