.wrapper {
  font-family: Lato;
  width: 100%;
  text-align: center;
  .header {
    height: 114px;
    width: 100%;
    background-color: #1c2435;
    box-shadow: 0 6px 13px 0 rgba(21, 33, 56, 0.53);
    .logo {
      margin-top: 24px;
    }
  }
  .body {
    margin-top: 64px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    .introduction {
      max-width: 888px;
      margin-left: auto;
      margin-right: auto;
    }
    .productName {
      color: #ea305d;
    }
    .instructions {
      max-width: 480px;
      margin: 48px auto;
      .appIcon {
        float: left;
      }
      .text {
        margin-left: 148px;
        padding-top: 18px;
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        font-weight: 400;
        text-align: left;
      }
      .download {
        padding: 0;
        font-size: 21px;
        font-weight: 500;
        margin-left: 25px;
        float: left;
      }
    }
  }
}
