.cards {
  padding-top: 30px;
  padding-left: 8px;
  padding-right: 8px;
  flex-direction: row;
  display:flex;
  flex-basis: content !important;
}

.userDevices {
  flex: 1;
  :global(.ui.card) {
    width: 460px;
    height: 255px;
  }
}

.roomType {
  flex: 1;
  :global(.ui.card) {
    width: 460px;
    height: 255px;
    float:right;
  }
}