@import "src/variables.scss";

:global(.ui.grid).columnWrapper {
  margin-left: 16px;
  max-width: 750px;
  .columnOne {
    width: 60% !important; // overriding semantic important
    border-right: 1px solid #e5e5e5;
    .header {
      font-size: 15px;
      font-weight: bold;
    }
    .options {
      margin-left: 16px;
      .questionIcon {
        margin-right: -5px;
      }
      .secondNotification {
        margin-left: 0;
      }
    }
  }
  .airplayProxy {
    margin-left: 35px;
  }
  .browserless {
    padding-right: 0px;
  }
  .secondColumn {
    padding-left: 50px;
    width: 40% !important; // overriding semantic important
    .header1 {
      font-size: 15px;
      font-weight: bold;
    }
    .header2 {
      margin-top: 0px;
      font-size: 15px;
      font-weight: bold;
    }
    .radioButtons {
      padding-left: 16px;
    }
  }
}
