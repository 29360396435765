@import 'src/variables.scss';
.wrapper {
  :global(.ui.table td) {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
}
.compatible {
  color: #db2828;
}

.allPodsButtons {
  margin-bottom: -16px;
}
@media (max-width: $lg-max) {
  .allPodsButtons {
    margin-bottom: 0px;
  }
}

.statusIcon {
  i,
  .loading {
    margin-right: 0;
  }
}

.unassigned > :global(.text) {
  font-style: italic;
  color: #767676 !important;
}

.podTemplate {
  &:global(.ui.selection.dropdown) {
    padding-left: 4px;
    padding-right: 0;
  }
}

.pendingClock {
  display: inline-block;
  vertical-align: middle;
}

.templateError {
  display: inline-block;
  vertical-align: super;
}

.pendingChange {
  display: inline-block;
  width: calc(100% - 22px);
}
