.roomLocationsMap {
  margin: auto 9%;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.charts {
  display: flex;
  padding: 1em 0;
  height: 475px;
  .usageHistory,
  .alerts {
    width: 50%;
    flex: 1;
  }
}

.body {
  padding: 15px;
  background-color: #e0e0e0;
}
.usageHistory {
  flex: 1;
  min-width: 0;
  height: 100%;
  margin-right: 15px;
}
.alerts {
  flex: 1;
  margin-bottom: 15px;
  height: 100%;
}
.col {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  margin-bottom: 15px;
  flex: 1;
  display: flex;
  flex-direction: row;
} 