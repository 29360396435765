.occupancy:global(.ui.card) {
  width: 392px;
  height: 274px;
  margin-top: 0;

  .content {
    display: flex;
    height: 100%;

    .contentLeft {
      text-align: center;
      padding: 24px 24px 24px 8px;
      border-right: 1px solid #eaeaea;
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .occupancy-number-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .occupancy-number {
          font-size: 75px;
          font-style: normal;
          font-weight: 400;
          line-height: 90px;
          letter-spacing: 0px;
          color: #70bf44;
        }

        .question-mark {
          margin-top: 10px;
          flex-shrink: 0;
        }
      }

      .occupancy-legend {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        margin: 0 auto;
      }

      .sol-users {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-top: 5px;
      }
    }

    .contentRight {
      padding: 24px 0 24px 10px;
      flex: 1;

      .tooltip {
        background: #fff;
        opacity: 0.6;
        padding: 10px;
        font-size: 10px;
        margin-top: -20px;
        line-height: 12px;
      }

      .average {
        display: flex;
        justify-content: center;
      }

      .average-number {
        font-style: normal;
        font-weight: normal;
        font-size: 32px;
        line-height: 38px;

        color: #70bf44;
        margin-right: 16px;
      }

      .average-label {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;

        color: #000000;
      }
    }
  }
}