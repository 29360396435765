@import "src/variables.scss";
.spaceType {
  width: 500px;
  display: flex;
  flex-direction: column;
}

.headerText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  padding-top: 32px;
  padding-bottom: 24px;
}

.rowGroup {
  display: flex;
  flex-direction: row;
}

.modRadioGroup {
  @extend .rowGroup;
  margin-top: 10px;
  margin-bottom: -20px;

}

.inputStyle {
  width: 250px;
  font-weight: 700;
  margin-bottom:16px !important;
  margin-bottom:8px !important;
}
.inputStyleError {
  width: 250px;
  font-weight: 700;
  color: #A1403E; 
}

.radioButton {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0px 10px;
}

.singleRoomImg {
  width: 300px;
}

.multiRoomImage {
  max-width: 560px;
}

.previewImg {
  width: 378px;
  height: 220.37px;
  margin: -15px 0 15px -5px;
}

.previewText {
  color: #A8A8A8;
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: -10px;
}

.routingCheckbox {
  margin-bottom: -15px;
}

.tipText {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.routingButton {
  margin: 15px 0 15px -30px;
  display: flex;
  align-content: center;
  justify-content: center;
  color: #1279C6;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  }
  .asterisk {
    color: red;
  }
  .error {
    color: #A1403E;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;    
  }

  .multiRoomImage {
  max-width: 560px;
}

.buttonHolder{
  display:flex;
  justify-content: space-between;
}
.nextButton {
  margin-top: 16px !important;
}