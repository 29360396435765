// These styles were stolen from IBM's Carbon Design system,
// so that's why they seem like they were written by a machine.
.numInput {
  position: relative;
  display: inline-block;
  max-width: 150px;
  padding: 0 8px 16px 0;
  .medium > span:global(.react-numeric-input) {
    width: 62px;
  }
  .large > span:global(.react-numeric-input) {
    width: 88px;
  }
  span:global(.react-numeric-input) {
    display: block;
    position: relative;
    height: 38px;
    b:nth-child(2) {
      position: absolute;
      right: 2px;
      width: 2.26ex;
      text-align: center;
      top: 4px;
      bottom: 50%;
      i {
        border-color: transparent transparent #3d70b2 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0px;
        height: 0px;
        border-width: 0px 0.6ex 0.6ex;
        border-color: transparent transparent #3d70b2;
        border-style: solid;
        margin: -0.3ex 0px 0px -0.56ex;
      }
    }
    b:last-child {
      position: absolute;
      right: 2px;
      width: 2.26ex;
      top: 50%;
      bottom: 8px;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0px;
        height: 0px;
        border-width: 0.6ex 0.6ex 0px;
        border-color: #3d70b2 transparent transparent;
        border-style: solid;
        margin: -0.3ex 0px 0px -0.56ex;
      }
    }
  }
  label {
    font-weight: 700;
    font-size: 15px;
    .required {
      padding-left: 4px;
      color: red;
    }
  }
  input[type="text"].input {
    -webkit-appearance: none;
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    box-sizing: border-box;
    display: block;
    font-size: inherit;
    font-weight: 300;
    height: 38px;
    line-height: normal;
    padding: 0.67857143em 1em 0.67857143em 0;
    position: relative;
    text-align: center;
    margin-top: 3px;
    &.medium {
      width: 62px;
    }
    &.large {
      width: 88px;
    }
    &.error {
      background-color: #fff6f6;
      border-color: #e0b4b4;
      color: #9f3a38;
      outline: none;
    }
    &:focus:not(.error) {
      border-color: #85b7d9;
      background: #fff;
      color: rgba(0, 0, 0, 0.8);
      -webkit-box-shadow: none;
      box-shadow: none;
      outline: none;
    }

    &:disabled ~ b > i {
      cursor: not-allowed;
      pointer-events: none;
    }

    &:disabled ~ b > i {
      fill: #c6c6c6;
    }

    &::-ms-clear {
      display: none;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }

    &:disabled,
    .number--readonly input {
      cursor: not-allowed;
      background-color: #f4f4f4;
      color: #c6c6c6;
    }
  }
}
