@import 'src/variables.scss';

.SolLinkToggle{
    width: 120px;
    font-size: 12px;
    line-height: 12px;

    .label{
        width:40%;
        float: left;
    }
    .selected{
        width:60%;
        float: left;
        color: $primary;
       &:hover{
        color:gray;
        cursor: pointer;
        }
    }
    .button{
        border: $light-grey 2px solid;
        padding: 11px;
    }
}