@import 'src/_mixins';
.filterMenu {
  * {
    @include light-scrollbar;
  }
  .body {
    padding-top: 12px;
    .categoryType:global(.header) {
      color: '#1b1c1d';
      font-size: 10px;
      font-weight: 300;
      margin-left: 16px;
      text-transform: uppercase;
    }
    .categories {
      padding-bottom: 4px;
      .category:global(.ui.item) {
        display: flex;
        * {
          flex-shrink: 0;
        }
        :global(.text) {
          flex-shrink: 1;
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        font-size: 14px;
        color: #3a3a3a;
        padding: 8px 16px;
      }
    }
  }
  :global(.ui.vertical.menu .dropdown.item) .submenu:global(.menu) {
    min-width: 100%;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 'fit-content';
    .loader {
      height: 20px;
    }
    .option {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .label {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}
