@import "src/variables.scss";

.categoriesCard:global(.ui.card) {
  min-height: 268px;
  &.hasCategories {
    :global(.content .header) {
      color: $primary;
    }
  }
  grid-column-start: 1;
  @media (min-width: $lg-min) {
    max-height: 279px;
  }
  :global(.content) {
    display: flex;
    flex-direction: column;
    :global(.header) {
      flex-shrink: 0;
      margin-bottom: 12px;
    }
    .categories {
      align-self: stretch;
      flex-grow: 1;
      flex-shrink: 1;
      @media (min-width: $lg-min) {
        overflow-y: auto;
        .category:not(:last-child) {
          margin-bottom: 12px;
        }
      }
      padding-right: 16px;
      @media (max-width: $md-max) {
        display: grid;
        grid-column-gap: 8px;
        grid-row-gap: 12px;
        grid-template-columns: repeat(3, 1fr);
        column-count: 3;
        padding-right: 0;
      }
      .category {
        padding: 0;
      }
    }
    .noCategories {
      margin: auto;
      .telescope {
        margin: 16px auto 24px auto;
        display: block;
      }
    }
  }
}
