.templateWrapper {
  margin: 0 16px 16px 16px;

  .calendarSettings {
    padding-left: 26px;

    .newRow {
      clear: left;
    }

    .file {
      margin-top: 24px;
    }

    .mailboxTypes {
      padding: 0 0 8px 16px;
    }

    .questionIcon {
      position: relative;
      top: 5px;
      left: 2px;
    }
  }

  .spanMargin {
    margin-top: 20px;
  }
}

.label{
  margin-bottom: 15px
}
