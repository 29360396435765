:global(.ui.card).card {
  margin-bottom: 0px;
  margin-top: 0px;
  min-width: 350px;
  min-height: 424px;
  :global(.content) {
    padding: 24px;
  }

  .header {
    font-size: 20px;
  }

  :global(.legend) {
    position: relative;
    top: 20px;
    padding-right: 0px;
  }

  .buttonContainer {
    width: 100%;
    text-align: center;

    :global(.ui.button) {
      font-weight: 700;
      text-transform: uppercase;
      width: 180px;
      margin: 0 auto;
      margin-top: 40px;
    }

    :global(.ui.button:not(.primary)) {
      color: #1279c6;
      width: 220px;
      text-align: center;
      background-color: white;
      border: 1px solid;
    }
  }
}
