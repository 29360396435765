.alerts-chart {
  .options {
    display: flex;
    flex-wrap: wrap;
    margin-left: 60px;
    .checkbox--UNREACHABLE {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #1cb5ac;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
    .checkbox--RESTARTED {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #2987cd;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
    .checkbox--OUT_OF_TEMPLATE {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #de3e96;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
    .checkbox--USB_REMOVED_EVENT {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #f0712c;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
    .checkbox--USB_ADDED_EVENT {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #a23bc5;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
    .checkbox--DEPLOYED {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #eaeaea;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
    .checkbox--IMPORTED {
      :global(.ui.checkbox) {
        label {
          &:before {
            border: none;
            background-color: #2db84b;
          }
          &:after {
            color: #fff;
          }
        }
      }
    }
  }
}
