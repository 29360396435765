@import 'src/variables.scss';

.usbPage {
  margin-bottom: 32px;
}

.cards {
  .top {
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    min-width: 690px;
    max-width: 1590px;
    @media (max-width: $lg-max) {
      max-width: 720px;
    }
    :global(.ui.card) {
      margin-top: 0;
      height: 256px;
      :global(.content) {
        padding: 16px;
      }
    }

    :global(.legend) {
      max-height: 100%;
    }

    button {
      top: 44%;
      opacity: 0;
    }

    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}