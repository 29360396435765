@import 'src/variables.scss';

.topRow {
  &:global(.react-multi-carousel-list) {
    display: block;
    flex: 1;
  }

  @media screen and (min-width: 970px) {
    :global(.react-multi-carousel-item:first-child) {
     :global(.ui.card) {
       margin-right: 8px;
     }
    }
    :global(.react-multi-carousel-item:last-child) {
      :global(.ui.card) {
        margin-left: 8px;
      }
    }
  }
  :global(.ui.card) {
    margin-left: auto;
    margin-right: auto;

  }

  :global(.react-multi-carousel-track) {
    float: left;
  }

  button {
    top: 44%;
    opacity: 0;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
}

.tabWrapper {
  .updatePodsButton {
    float: right;
  }

  :global(.ui.grid > .row > .column).leftColumn {
    padding-right: 2rem;
  }

  .updateSection {
    margin-top: -75px;
    float: right;
  }
}
.solsticeHeader {
  font-weight: 800;
}
.updateButtons {
  display: inline-flex;
  margin-top: 40px;
  margin-left: 10px;

  .orText {
    margin-left: 15px;
    margin-right: 20px;
    margin-top: 5px;
    font-style: italic;
    font-size: 16px;
  }
}

.releasesDropDown {
  margin: auto !important;
  width: 170px !important;
  font-size: 1rem !important;
  z-index: 501 !important;
}
.selectedPodslbl {
  padding-top: 15px;
}

.updateContent {
  padding-top: 25px;
}

.datePicker {
  margin-top: 20px !important;
  .atText {
    margin-top: 10px !important;
  }

  .date {
    height: 38px;
    width: 100%;
    text-align: center;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
  }

  .time {
    height: 38px;
    width: 100%;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
    text-align: center;
  }

  .time:active,
  .time:focus,
  .date:active,
  .date:focus {
    border: 1px solid $primary;
    border-radius: 4px;
  }
}

.disclaimer {
  font-size: 12px;
  padding-top: 10px;
}

.schedule {
  margin-top: 35px !important;
}
.notification {
  margin-top: -12px;
  margin-left: 0;
}

:global(.flatpickr-calendar.hasTime.noCalendar.animate.open.arrowTop) {
  width: 154px;
}

.right-column {
  * {
    margin:0;
    padding:0;
  }
  h1 {
    font-size: 18px;
    font-weight: 700;
  }
  :global(.ui.bulleted.list > div.item)::before {
    color: $dark-blue;
  }
  .bulletedList {
    font-size: 12px;
    .bulletInfo {
      font-size: 12px;
      margin-top: 1px;
      :global(.header) {
        font-weight: 400;
      }
    }
  }
  .bulletInfoText {
    font-size: 12px;
  }
  .learnMore {
    position: absolute;
    bottom: 33px;
    left: 135px;
  }
  .learnMore:hover {
    cursor: pointer;
  }
}

.allUpdated {
  margin-right: 10px;
  h1 {
    margin-left: 16px;
  }
  .versionWrapper {
    margin-top: -15px;
    .version {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  .queued {
    margin-left: 1.45em;
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
    font-style: italic;
  }
  .tasks {
    margin-left: 1.45em;
    font-size: 14px;
    margin-top: 10px;
    color: $primary;
  }
  .tasks:hover {
    cursor: pointer;
  }

  .pods {
    font-size: 12px;
    margin-top: -19px;
  }

  .checkWrapper {
    text-align: center;
    .greenCheck {
      width: 85%;
    }
  }
}

:global(.ui.card).card {
  min-height: 270px;
  min-width: 392px;
  width: 450px;
}

@media (min-width: 768px) and (max-width: 992px) {
  :global(.ui.divided.grid:not([class*='vertically divided'])
      > .row
      > .column:first-child) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  :global(.ui.divided.grid:not([class*='vertically divided'])
      > .row
      > .column:first-child).leftColumn {
    height: 310px;
  }

  :global(.ui.divided.grid:not([class*='vertically divided'])
      > .row
      > .column) {
    -webkit-box-shadow: 0 -1px 0 0 rgba(34, 36, 38, 0.15) !important;
    box-shadow: 0 -1px 0 0 rgba(34, 36, 38, 0.15) !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

@media (max-width: 767px) {
  :global(.ui.divided.grid:not([class*='vertically divided'])
      > .row
      > .column:first-child).leftColumn {
    height: 310px;
  }
}

.viewTasks {
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
  color: $primary;
}

.viewTasks:hover {
  cursor: pointer;
}

.legendTasks {
  font-size: 12px;
  font-weight: 700;
  margin-top: 15px;
  font-style: italic;
  width: 280px;
}