.legend {
  padding: 0;
  list-style: none;
  &.horizontal li {
    display: inline;
    + li {
      margin-left: 12px;
    }
  }
}

.label {
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 12px;
  line-height: 15px;
}

.bullet {
  width: 9px;
  height: 9px;
  border-radius: 50px;
  display: inline-block;
  margin-right: 9.42px;
}
