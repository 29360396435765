@import "src/_variables";

:global(.ui.sortable.table thead th.sorted).emphasize {
  background-color: #e2e3e4;
}

:global(.ui.table).dataTableSlim {
  margin-top: 0px;
  border-left: none;
  border-right: none;
}

:global(.ui.table th.collapsing),
:global(.ui.table td.collapsing) {
  max-width: 150px;
}

:global(.ui.table th.collapsing):last-child,
:global(.ui.table td.collapsing):last-child {
  max-width: inherit;
}

.table {
  :global(.ui.sortable.table thead th) {
    .headerCell {
      display: flex;

      .displayName {
        flex-grow: 1;

        .headerTooltips {
          position: relative;
          bottom: -4px;
          margin-left: 4px;
          padding: 1.5px;
        }
      }

      &.onsort-icon {
        &:after {
          content: "\f0dc";
          font-family: Icons;
          margin: auto 4px auto 4px;
        }
      }
    }

    &:global(.ascending) {
      .headerCell:after {
        content: "\f0de";
      }
    }

    &:global(.descending) {
      .headerCell:after {
        content: "\f0dd";
      }
    }

    &:after {
      display: none;
    }
  }
}

.wrapper {
  &.padded {
    padding-left: 16px;
    padding-right: 16px;
  }

  .table {
    border-right: 1px solid rgba(34, 36, 38, 0.15);
    border-left: 1px solid rgba(34, 36, 38, 0.15);

    &.scrollable {
      width: 100%;
      overflow-x: scroll;
    }

    &.expand-bottom {
      padding-bottom: 30px;
    }
  }

  .tableFooter {
    margin-top: -2px;
    width: 100%;
    min-height: 51px;
    margin-left: 0px;
    padding-top: 14px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-top: none;
    border-radius: 0.28571429rem;
    background-color: #f9fafb;
  }

  .tableHeaderWrapper {
    margin-bottom: -3px;

    .tableHeader {
      display: flex;
      background-color: #f9fafb;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-radius: 0.28571429rem;
      -moz-border-radius-bottomleft: 0;
      -moz-border-radius-bottomright: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 20px 0px 5px 10px;

      .searchBarInput {
        flex: 0.5 1 auto;
      }

      .tableName {
        margin-top: 10px;
        text-align: center;
        flex: auto;
      }

      .columnManager {
        flex: 0.3 1 auto;
        display: flex;
        justify-content: flex-end;
      }
    }

    .manageColumns {
      margin-top: 10px;
      float: right;

      .manageText {
        font-size: 14px;
        display: inline;
      }
    }

    .columnSelect {
      display: flex;
      flex-wrap: wrap;
      background-color: #f9fafb;
      height: 100%;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-top: none;
      padding: 15px;

      .columnSelectCheckbox {
        margin-bottom: 10px;
        flex: 1 1 20%;
        max-width: 20%;

        label {
          font-weight: bold;
        }
      }
    }

    .columnSelect::after {
      content: "  ";
      flex: 20%;
    }

    .searchInput {
      width: 100%;

      .searchIcon {
        position: absolute;
        left: 22px;
        top: 10px;
        color: #909090;
        display: none;
      }

      .clearSearch {
        position: absolute;
        top: 10px;
        right: 5px;
        color: #909090;
      }

      .clearSearch:hover {
        cursor: pointer;
      }
    }

    .filterPills {
      .pill {
        margin-bottom: 4px;
        float: left;

        &:global(.ui.pink) {
          background-color: #ea305d !important;
        }
      }

      min-height: 60px;
      height: auto;
      background-color: #f9fafb;
      border: 1px solid rgba(34, 36, 38, 0.15);
      border-top: none;
      padding: 15px;
    }
  }
}

@media (min-width: $lg-min) {
  .allPods {
    .tableHeaderWrapper {
      .tableHeader {
        .searchBarInput {
          flex-basis: 25%;

          &.tableHeaderColumnAutoWidth {
            flex-basis: auto;
            width: auto;
          }
        }
        .tableName {
          flex-basis: 50%;

          &.tableHeaderColumnAutoWidth {
            flex-basis: auto;
            width: auto;
          }
        }
        .columnManager {
          flex-basis: 25%;

          &.tableHeaderColumnAutoWidth {
            flex-basis: auto;
            width: auto;
          }
        }
      }

      .columnSelect {
        .columnSelectCheckbox {
          flex: 1 1 33.33%;
          max-width: 33.33%;
        }
      }

      .columnSelect::after {
        content: "  ";
        flex: 33.33%;
      }
    }
  }
}

@media (max-width: $md-max) {
  .allPods {
    .tableHeaderWrapper {
      .tableHeader {
        flex-wrap: wrap;

        .searchBarInput {
          flex-basis: 100%;
        }
        .tableName {
          flex-basis: 100%;
          text-align: left;
        }
        .columnManager {
          flex-basis: 100%;
        }
      }

      .manageColumns {
        .manageText {
          font-size: 14px;
          display: inline;
        }
      }

      .columnSelect {
        .columnSelectCheckbox {
          flex: 1 1 40%;
          max-width: 40%;
        }

        .columnSelect::after {
          content: "  ";
          flex: 40%;
        }
      }
    }
  }
}

.filterButton:global(.ui.button) {
  border: 2px solid #1279c6;
  color: #1279c6;
  background-color: white;
  font-size: 12px;
  padding: 8px;
  padding-bottom: 7px;
  width: 147px;
  height: 36px;
}
