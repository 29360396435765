:global(.ui.form) {
  :global(.fields) {
    .input:global(.field) {
      margin: 0 0 1.5rem;
      min-width: 24rem;
      width: 350px;
      padding: 0;
    }
  }
  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
}
.button {
  width: 350px;
  margin-bottom: 18px;
}
.error {
  color: #d0021b;
  margin-bottom: 18px;
}
.back {
  width: 360px;
  margin-bottom: 10px;
}
.buttonLink {
  background: transparent;
  border: 0;
  padding: 0;
  color: #4183c4;
  cursor: pointer;
  font-family: "Lato";
}
.loginButton {
  width: 350px;
  margin-bottom: 18px;
  > div {
    width: 100%;
    > button {
      width: 100%;
      margin-bottom: 18px;
    }
  }
}
.hidden {
  display: none;
}
