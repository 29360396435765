.header {
  padding: 16px 0;
  font-size: 22px;
  font-weight: 700;
  .icon {
    margin-left: 5px;
  }
  .grey {
    color: grey;
  }
}
.subHeader {
  font-size: 16px;
}
.accessHeader {
  font-size: 20px;
}

.buttonHolder{
  display:flex;
  justify-content: space-between;
}
.addPodsNextButton {
  margin-top: 16px !important;
}
.addPodsBackButton {
  margin-top: 16px !important;
}

.instructions {
  padding-top:10px;
}
.instructionsInvalid{
  color: red;
}
.instructionsList {
  color:red;
}
.atLeastTwoPods {
  color: black;
}
.onePrimaryPod {
  color:black;
}
.hideCheck{
  display:none !important;
}

.primaryPodRadio {
  margin: 0px;
}

.podsTally {
  font-size: 16px;
}

.solsticeRoutingDescription {
  font-size: 14px;
  font-weight: 700;
  max-width: 653px;
  padding-bottom: 16px;
}