.templateWrapper {
  margin: 0 16px 16px 16px;
  .settings {
    margin-left: 26px;
    .row > * {
      display: inline-block;
    }
    .thumbnailText {
      color: #1B1C1D;
      font-size: 12px;
      font-style: italic;
      font-weight: 300;
      margin-bottom: 8px;
    }
    .screenInstructionOptions {
      padding-left: 16px;
      vertical-align: top;
      .instructionOptions {
        margin-left: 26px;
      }
    }
  }
}