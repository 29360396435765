@import 'src/variables.scss';

:global(.ui.card).solCard {
  border: 1px #f8f8f8 solid;
  border-radius: 4px;
  margin: 0px;
  margin-bottom: 16px;
  width: 100%;
  min-height: 117px;
  padding: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  z-index: $zIndexMedium + 1;
  &.shadow {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  :global(.ui.header .sub.header).subHeader {
    color: black;
    font-size: 14px;
    padding-top: 5px;
    margin-bottom: 8px;

    label {
      font-weight: normal;
    }
  }
}

.filterSection {
  display: flex;
}

.filterButton {
  margin-right: 8px;
}

.chart-container {
  position: relative;
}

.dropdown-container {
  position: absolute;
  right: 100px;
  top: 20px;
  z-index: $zIndexMedium;
}
