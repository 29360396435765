@import 'src/variables.scss';

.notification{
    border-radius: 10px;
    display: inline;
    font-size: 10px;  
    margin: 5px;
    padding: 2px 3px;
    vertical-align: text-top;
    font-weight: normal;
    &.menu {
        background-color: $light-blue;
        color: $black;
    }
    
    &.info {
        background-color: $dark-blue;
        color: $white;
    }
    &.beta {
      background-color: $warning;
      color: $black;
    }
}
    
.warning {
    float: unset;
    color: $white;
}
