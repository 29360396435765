.securityTypeWrapper {
  margin-left: 24px;
  display: block;
  .securitySingles {
    display: inline-block;
    margin-right: 8px;
  }
}
.ssidName {
  margin-bottom: 8px;
  font-size: 18px;
  .name {
    font-weight: bold;
  }
}
.wepPassword {
  margin-bottom: -8px;
}