.exclusions {
  margin-bottom: 16px;

  .header {
    font-size: 13px;
    font-weight: 700;
  }

  textarea {
    resize: none;
    height: 76px;
    width: 376px;
    border: 1px solid rgba(34, 36, 38, .15);
    box-sizing: border-box;
    border-radius: .28571429rem;
    padding: .67857143em 1em;
  }

  textarea:focus {
    border-color: #85b7d9;
    outline: none;
  }

  textarea::placeholder {
    font-style: italic;
    color: #1B1C1D;
    font-weight: 300;
    font-size: 14px;
  }

}