@import 'src/variables.scss';

.legend {
  margin-top: 1.4286rem;
  height: 17.857143rem;
  padding-right: 1rem;
  overflow: hidden auto;
}

.circle {
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  align-self: center;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 320px;
  line-height: 1rem;
  margin-bottom: 0.7857rem;
}

.gray {
  color: #9b9b9b;
  flex-shrink: 0;
}

.text {
  display: flex;
  color: #4a4a4a;
  font-size: 12px;
  align-items: center;
  flex: 1;
}

.name {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
}

.flexy {
  flex: 1;
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 2.28571rem;
  padding-right: 1rem;
  line-height: 2.28571rem;
}

.submenu {
  min-width: 37.5%;
}

.editIcon {
  position: relative;
  top: 1px;
  color: $primary;
}
