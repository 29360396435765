@import "src/variables.scss";

.segment {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 400px;
}

.refreshSegment {
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 450px !important; //override sematic ui ;
}

.header {
  font-size: 24px;
  padding: 15px;
  padding-bottom: 5px;
}

.newHeader {
  font-size: 24px;
  padding: 15px;
  padding-bottom: 10px;
}

.roomLocs {
  width: calc(100% - 200px);
  float: left;
}

.checkboxes {
  padding: 0px;
  width: 200px;
  float: left;
  margin-top: -3px;

  .box {
    padding: 0px;
    margin: 0px;
    width: 50%;
    float: left;
  }
}

.subheader {
  font-size: 12px;
  padding: 15px;
  padding-top: 0px;
}

.alerts {
  border: 2px solid rgb(211, 190, 0);
  padding: 0px;
  margin: 0px;
  height: 100%;
  border-radius: 5px;
}

.none {
  border: 2px solid transparent;
  padding: 0px;
  margin: 0px;
  height: 100%;
  border-radius: 5px;
}

.map {
  border-radius: 5px;
  height: 100%;
  min-height: 300px; // for safari - smallest the map gose with  height: 100%;
  width: 100%;
  z-index: $zIndexLow;

  .podDot {
    z-index: $zIndexLow;
  }

  .mapButtons {
    display: inline-block;
    line-height: 48px;
    flex-shrink: 0;

    .button {
      margin-right: 10px;
    }
  }

  .targetIcon {
    height: 24px;
    width: 24px;
    filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0));
  }

  .controlButtonsOut {
    z-index: $zIndexLow;
    right: 13px;
    top: 7px;
    position: absolute;
    background-color: #fff;
    padding: 0px 2px;
    padding-top: 2px;
    margin: 0px;
    border-radius: 4px;
    border: 2px solid rgba(0, 0, 0, 0.25);

    &:hover {
      background-color: rgb(228, 226, 226);
      cursor: pointer;
    }
  }

  .podPageButton {
    border-radius: 5px;
    margin-top: 20px;
    height: 27px;
    min-width: 120px !important;
    line-height: 10px;
    background-color: white;
    border: 1px #1279c6 solid;
    color: #1279c6;
    position: absolute;
    bottom: -59px;
    left: -15px;

    &:hover {
      background-color: lightgrey;
    }

    .displaynone {
      display: none;
    }
  }
}

.links {
  text-align: right;
  font-size: 14px;
  color: #1279c6;
  margin: 0px;
  padding: 5px;
}

:global(.leaflet-container) {
  background-color: #d4dadc;
  background: #d4dadc;
}