@import 'src/variables.scss';

.ethernetWrapper {
  .ethernetOptions {
    min-width: 200px;
    min-height: 350px;
  }
  
@media (min-width: $xl-min) {
  .borderBottom {
      border-bottom: 1px solid #e5e5e5;
      margin-bottom: 32px;
    }
  .borderRightXl {
      border-right: 1px solid #e5e5e5;
      height: 100%;
    }
  }
}
@media (min-width: $lg-min) {
  .borderRightLg {
    border-right: 1px solid #e5e5e5;
    height: 100%;
  }
}
.staticIpTooltip:global(.ui.top.left.popup.transition.visible) {
  margin-left: 105px;
}
