@import 'src/variables.scss';

.flexRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  line-height: 38px;
  vertical-align: middle;
}
.enabledForActive {
  font-style: italic;
  color: #2185d0;
}
.enabledForInactive {
  font-style: italic;
  color: #cacbcd;
}
.anchorActive {
  cursor: pointer;
  color: #2185d0;
}
.anchorInactive {
  cursor: pointer;
  color: #cacbcd;
}
.toggleCell {
  min-width: 75px;
  padding-top: 20px;
  vertical-align: top;
}
.accordionInactive {
  transition: line-height 0.2s ease-in;
  line-height: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
.accordionActive {
  transition: line-height 0.3s ease-out;
  line-height: 38px;
  opacity: 1;
  overflow: visible;
}
.categoryName {
  color: #1b1c1d;
  font-size: 10px;
  line-height: 12px;
  width: 100%;
  height: 12px;
}
.categoryRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.categoryCol {
  display: flex;
  flex-direction: column;
  min-width: 150px;

  margin: 0px 65px 10px 0px; // top right bottom left
  padding: 0px 10px 0px 0px;
  border-bottom: 1px solid $grey;
}
.alertFilterDropdown {
  float: right;
  line-height: inherit;
  margin-right: 3px;
  width: 100%;
  :global(i.dropdown.icon) {
    position: absolute !important; // override semantic ui position of icon
    right: -5%;
    top: 35%;
  }
}
.dropdownItem {
  min-width: 150px;
}
.checkMark {
  float: right;
  text-align: right;
  padding-left: 5px;
}
.visible {
  transition: opacity 0.2s ease-out;
  opacity: 1;
}
.invisible {
  transition: opacity 0.1s ease-in;
  opacity: 0 !important;
}
.dropdownColumn {
  padding-left: 1em;
  padding-right: 1em;
}
.usbDeviceDropdown {
  min-width: 192px;
}

// Filter Pills
.pillContainer {
  padding: 20px 0px;
}
.pill {
  margin: 8px 0px;
}
.labelPill {
  font-size: 12px;
  padding-left: 8px;
  line-height: 14px;
  vertical-align: middle;
}
.closeIcon {
  font-size: 14px;
  padding-right: 4px;
  display: inline;
  line-height: 14px;
  vertical-align: middle;
}
.removeAll {
  cursor: pointer;
  font-size: 12px;
  line-height: 28px;
  text-indent: 7px;
  margin-left: 7px;
}
.elementInfo {
  display: flex;
  margin-left: 8px;
  cursor: pointer;
}
