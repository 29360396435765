.drawer {
  .header {
    font-size: 20px;
    font-family: Lato Regular, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-align: center;
    line-height: 24px;
  }
  hr {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .title {
    font-size: 20px;
    h1 {
      display: inline-block;
      padding: 0;
      margin-top: 0;
    }
    span {
      position: relative;
      top: -5px;
    }
  }

  .inputs {
    margin-top: 8px;
    margin-right: -8px;
  }
  .saveButton {
    display: flex;
    justify-content: flex-end;
  }
  .progress {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    :global(.ui.button) {
      box-shadow: none !important;
      padding: 0;
    }
    span {
      font-size: 18px;
      font-weight: 900;
      line-height: 22px;
    }
  }
}
