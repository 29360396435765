@import "src/variables.scss";

.loginWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  background-image: url(../../assets/cloud_login_desktop.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  @media (max-width: $md-min) {
    background-image: url(../../assets/cloud_login_small.png);
  }
  .loginLogo {
    height: 100px;
    width: 350px;
    margin-bottom: 32px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .loginContainer {
    padding: 60px;
    min-height: 376px;
    width: 500px;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: left;
    box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
    .joinNow {
      margin-top: 30px;
    }
  }
}

.error {
  color: #d0021b;
  margin-bottom: 18px;
}

.errorSpace {
  height: 38px;
}
.orgIdentifiers {
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 4px;
}