.legend {
  height: 17.857143rem;
  overflow: hidden auto;
}
.circle {
  margin-right: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  align-self: center;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 300px;
}
gray {
  color: #9b9b9b;
}
.text {
  display: flex;
  color: #4a4a4a;
  font-size: 12px;
  align-items: center;
  flex: 1;
}
.flexy {
  flex: 1;
}
.controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 15px 0px 15px 0px;
}
.menu {
  min-width: 300px;
}
.itemMenuContent {
  padding: 0 3.5rem 0 0.7em;
  display: flex;
  justify-content: space-between;
  line-height: 1rem;
  position: relative;
}
.optionName {
  text-overflow: ellipsis;
  overflow: hidden;
}
.itemMenuLabel {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.trashCan {
  cursor: pointer;
  flex-shrink: 0;
}
.submenu {
  min-width: 37.5%;
}
.addNew {
  margin-left: 0.6em;
}
