@import 'src/variables.scss';

.card:global(.ui.card) {
  margin-right: auto;
  margin-left: 8px;
  width: 392px;
  @media (max-width: $lg-max) {
    margin-right: auto;
    margin-left: auto;
  }
}
