.enabledWrapper {
  min-width: 200px;
  margin-left: 16px;
  .eapWrapper {
    margin-left: 28px;

  }
  .block {
    display: block;
    width: 100%;
    margin-bottom: 16px;
    .phase2 {
      font-size: 15px;
      margin-bottom:5px;
      font-weight: bold;
    }
  }
}
