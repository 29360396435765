.parent {
  display: flex;
  flex: 1;
}

.menu-accordion-title i {
  display: none !important;
}

.fullScreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: #fff;
}
