@import 'src/variables.scss';

.cards {
  margin: 0px;
  width: 99%;
  margin-bottom: 1em;
}

.aggregateCard {
  :global(.ui.header).header {
      font-size: 20px;
      margin: 0px;
      margin-bottom: 5px;
  }
  .questionIcon {
    position: relative;
    bottom: -3px;
    cursor: pointer;
  }
    .count {
      font-weight: bold;
    }
    .description{
      height: 100px;
      width: 400px;
      margin: 25px auto;
      font-size: 18px;
      line-height: 30px;
      .leftSide{
        float: left;
        width: 49%;
        padding: 10px;
        padding-top: 25px;
        text-align: center;
        .count { 
            color: #3388ff;
            font-size: 60px;
            padding: 5px 0px;
            
            position: relative;
            top: 1px;
        }
        .label{
          padding-top: 10px;
          font-weight: bold;
        }
      }
      
      .rightSide{
        border-left: solid 2px $grey;
        float: right;
        width: 51%;
        padding: 10px;
        padding-left: 30px;
        
        .label{
          position: relative;
          top: -5px;
          padding-left: 15px;
          padding-bottom: 0px;
        }
      }
    }
    .unassigned{
      text-align: center;
      font-size: 16px;
      position: relative;
      bottom: 0px;
      padding-top: 10px;
      .filter{ 
        color: #2987CD;
        font-weight: bolder;
        position: relative;
        top: -5px;
        padding-bottom: 0px;
      
        &:hover{
          color: $grey;
          cursor: pointer;
        }
      }
      .label{
        position: relative;
        top: -5px;
        padding-left: 15px;
        padding-bottom: 0px;
      }
    }
    .green{
      color: #70BF44;
    }
    .alert{
      color: #BF1244;
    }
}
