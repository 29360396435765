
.topRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  > :global(.ui.card) {
    height: 255px;
    min-width: 450px;
    margin-bottom: 0;
    margin-left: 0;
  }
}

