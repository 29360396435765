
.vlanWrapper {
  padding-bottom: 10px;
  padding-left: 26px;
  min-width: 200px;
  .vlanEnabled {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 10px
  }
  .vlanFields {
    margin-top: 14px;

    .adminConfig {
      margin-top: 24px;
      margin-bottom: 10px;
    }
      input[type="number"] {
        position: relative;
        width: 80px;
      }
    }
}
@media (max-width: 991px) {
  .vlanWrapper {
    border-bottom: 1px solid #e5e5e5;
  }
}
