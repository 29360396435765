.user-collaboration:global(.ui.card) {
  width: 200px;
  .header:global(.ui.header) {
    margin-bottom: 16px;
    font-size: 20px;
  }
  :global(.recharts-tooltip-wrapper) {
    text-transform: capitalize;
  }
  .chart {
    display: flex;
    justify-content: center;
  }
  .collaborators {
    text-align: center;
    .number {
      color: #163296;
    }
  }
  .presenters {
    text-align: center;
    margin-top: 16px;
    .number {
      color: #1eb3c9;
    }
  }
  .number {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .title {
    color: #333333;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
  }
  .text {
    color: #333333;
    font-size: 12px;
    line-height: 15px;
  }
}
