@import 'src/variables.scss';

.most-collaborative-spaces {
  display: flex;

  .card:global(.ui.card) {
    min-height: 350px;
    display: flex;
    flex: 1;

    & .cardContent {
      display: flex;
      flex: 1;
    }

    .header {
      font-size: 20px !important;
    }

    .sub-text {
      font-size: 12px;
      color: #333333;
    }

    .map-container {
      flex: 1;
    }

    .top-ten {
      display: flex;
      margin-left: 24px;
      margin-top: 24px;
    }
  }

  .card-content {
    display: flex;
    flex: 1;
  }
}