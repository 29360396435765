.isInline {
  display: inline-block;
  margin-right: 5px;
}

.text {
  text-align: left;
}

.centeredButton {
  text-align: center;
}
