@import 'src/variables.scss';
.solMap{
    .map{
        margin: 0px auto;
        height: 98%;
        min-height: 425px; // for safari
        width: 99%;
        z-index: $zIndexLow;
    }

    .controlButtonsOut {
        z-index: $zIndexLow;
        right: 12px;
        top: 10px;
        position: absolute;
        background-color: #fff ; 
        padding: 0px 2px  ; 
        padding-top: 2px ; 
        margin: 0px  ; 
        border-radius: 4px;
        border: 2px solid rgba(0, 0, 0, 0)  ; 
        filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.35)); 
        &:hover {
          background-color: rgb(228, 226, 226) ; 
          cursor: pointer;
        }
      }

      .targetIcon {
        height: 18px;
        width: 18px; 
        filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0)); 
      }
}