@import 'src/variables.scss';
:global(.ui.menu) .menuItem:global(.item) {
  padding-left: 5px;
  z-index: $zIndexHigher;
  .menuDropdown {
    i {
      margin-right: 0px;
    }
  }
}

.solstice {
  margin: -10px 0 -10px 0 !important;
  width: 7.5em !important;
}

.menuHeader{
  color: black;
  width: 250px;
  padding: 15px 20px;
  border-bottom: 1px solid black;
}

.menuHeaderTruncate{
  color:black;
  width: 250px;
  padding: 15px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid grey;
}
