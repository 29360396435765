@import 'src/variables.scss';

.useageIcon {
  border: hotpink 2px solid;
  padding: 0px;
  margin: 0px;
  position: relative;
  top: 32px;
}

.alertCount {
  height: 20px;
  width: 50px;
  position: relative;
  top: -10px;
}


//tooltips in here :
:global(.leaflet-container) {
  :global(.leaflet-tooltip-pane) {
    .dotTooltip {
      margin-top: 20px;
      height: 24px;
      line-height: 10px;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      border: 2px solid rgba(0, 0, 0, 0.6);
      position: absolute;
      bottom: -56px;
      left: 0px;
      min-width: 100px !important;
      text-align: center;

    }

    .buildingTooltip {
      margin-top: 20px !important;
      padding: 0px;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      border: none;
      overflow-x: auto;
      word-wrap: break-word;
      padding: 7px;

      p {
        font-size: 13px;
        line-height: 12px;
        margin: 0px;
        padding: 1px;
      }
    }

    .alertsTooltip {
      margin-top: 20px;
      margin-left: 25px;
      line-height: 10px;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      border: 2px solid rgb(211, 190, 0);
      position: absolute;
      bottom: -56px;
      left: 0px;
      min-width: 100px;
      text-align: center;

    }

    .alertsTooltip::before {
      display: none;
    }
  }

  .podPageButton {
    border-radius: 5px;
    margin-top: 20px;
    height: 27px;
    min-width: 120px !important;
    line-height: 10px;
    background-color: white;
    border: 1px #1279C6 solid;
    color: #1279C6;
    position: absolute;
    bottom: -59px;
    left: -15px;

    &:hover {
      background-color: lightgrey;
    }
  }

  :global(.leaflet-popup-content-wrapper) {
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    box-shadow: none;
  }

  :global(.leaflet-popup-tip) {
    display: none;
  }

  :global(.leaflet-control-zoom.leaflet-bar.leaflet-control) {
    margin-right: 13px;
    margin-top: 44px;
    z-index: $zIndexLow;
  }

}