@import "src/variables.scss";

.name {
  font-style: normal;
  * {
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  a {
    display: block;
  }
  .ip {
    font-size: 10px;
    color: black;
  }
}
