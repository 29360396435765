.conf-card {
  :global(.ui.card) {
    min-height: 370px;
  }
  .meetings-legend {
    position: absolute;
    right: 30px;
    top: 20px;
    width: 100px;
  }
  .header {
    :global(.ui.header) {
      margin-top: 0;
      font-size: 20px;
    }
  }
  .proximateCard{
    width:100%;
  }

  .meetings-content {
    :global(.modified-position) {
        left: -50px;
        top: 5px;
      }

    :global(.recharts-layer) {
      font-size: 12px;
      color: #333333;
      font-weight: bold;
    }
  }
}
