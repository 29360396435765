@import 'src/variables';

.container {
  position: relative;
  display: flex;
  flex: 1;
  z-index: $zIndexHigh;
}
.page {
  flex: 1;
  width: 100%;
}
.menu {
  width: 200px;
}
.sidebar {
  min-width: auto;
  overflow-y: scroll;
  z-index: 1000;
  overflow-x: hidden;
  transition: left 250ms ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.downloadButton {
  align-self: flex-end;
  margin-bottom: 10px;
  margin-left: 16px;
  width: 166px;
  z-index: -1; // prevents the icon from bleeding through to the menu
}
.buttonText {
  margin-right: 5px;
}
.content {
  width: 100%;
  display: flex;
  position: relative;
}
.loading {
  flex: 1;
}
@media (max-width: 768px) {
  .sidebar {
    position: absolute;
    left: -218px;
    top: 0;
    height: 100%;
  }
  .sidebar-open {
    left: 0;
  }
}
