.trialDrawer {
  align-content: center;
  text-align: center;
  font-family: Lato;
  h1 {
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    margin: 0 -5px 16px;
  }
  .logo {
    margin-bottom: 24px;
  }
  .text {
    font-size: 20px;
    line-height: 24px;
  }
  .note {
    font-style: italic;
    margin-top: -16px;
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 17px;
  }
  .sendQuote {
    margin-top: 12px;
    margin-right: 8px;
    display: flex;
    justify-content: flex-end;
  }
  .download {
    margin-top: 16px;
  }
  .formWrapper {
    margin-top: 32px;
    width: 386px;
    text-align: left;
  }
}
