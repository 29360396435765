@import 'src/variables.scss';

.gpsTargetIcon {
  padding: .5px;
  margin: 1px;
  height: 30px;
  width: 30px;
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.5));
}

.outer {
  margin: 0px;
  border: 0px;
  height: 0px;
  width: 0px;
}

.mapDot {
  margin: 1px;
  padding: 0.5px;
  height: 15px;
  width: 15px;
  filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.5));
 
}

.mapDot:hover {
  fill: $grey !important; //overriding the fill of the map dot 
}

.green {
  z-index: $zIndexLow;
  position: relative;
  right: 18px;
  top: -15px;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background: radial-gradient(circle at center,
      rgba(77, 155, 50, 0.7) 0%,
      rgba(77, 155, 50, 0.35) 35.42%,
      rgba(196, 196, 196, 0) 100%);

  .mapDot {
    position: relative;
    right: -16px;
    top: 15px;
  }
}

.yellow {
  z-index: $zIndexLow;
  position: relative;
  right: 25px;
  top: -23px;
  height: 62.5px;
  width: 62.5px;
  border-radius: 31px;
  background: radial-gradient(circle at center,
      rgba(251, 132, 45, 0.7) 0%,
      rgba(251, 192, 45, 0.7) 20.31%,
      rgba(255, 227, 78, 0.7) 37.5%,
      rgba(77, 155, 50, 0.357) 66.15%,
      rgba(77, 155, 50, 0.007) 87.4%,
      rgba(196, 196, 196, 0) 100%);

  .mapDot {
    position: relative;
    right: -23px;
    top: 23px;
  }
}


.red {
  z-index: $zIndexLow;
  position: relative;
  right: 32px;
  top: -28px;

  height: 75px;
  width: 75px;
  border-radius: 37.5px;
  background: radial-gradient(circle at center,
      rgba(176, 0, 39, 0.7) 18.23%,
      rgba(251, 132, 45, 0.7) 28.65%,
      rgba(251, 192, 45, 0.7) 39.06%,
      rgba(255, 227, 78, 0.7) 45.83%,
      rgba(77, 155, 50, 0.357) 65.53%,
      rgba(77, 155, 50, 0.007) 87.4%,
      rgba(196, 196, 196, 0) 100%);

  .mapDot {
    position: relative;
    right: -30px;
    top: 28px;
  }
}
