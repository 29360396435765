@import './variables';

@function is-px($value) {
  @return ($value * 0) == 0px;
}

@function px-to-rem($px-value) {
  @if not is-px($px-value) {
    @error "Value passed into px-to-rem needs to be a pixel unit value.";
  }

  $converted-value: $px-value / $base-font-size;
  @return unquote($converted-value + 'rem');
}
