.ui.toggle.checkbox input~label:before {
    background-color:#F3F3F3 !important;
}

.ui.toggle.checkbox audioIcon input~label:after {
    background-color:#F3F3F3 !important;
    background-image: url(../../assets/active_learning/audio_icon.png);
    background-position: center;
    background-repeat: no-repeat;
}

.ui.toggle.checkbox input:checked~label:before {
    transition: 500ms ease-in-out;
    background-color:#66CC33 !important;
}
.ui.toggle.checkbox input:focus:checked~label:before {
    transition: 500ms ease-in-out;
    background-color:#66CC33 !important;
}

.ui.toggle.checkbox audioIcon input:checked~label:after {
    background-image: url(../../assets/active_learning/audio_icon.png);
    background-position: center;
    background-repeat: no-repeat;
}
