:global(.ui.progress.active).progressBar {
  margin: 0px 10px;
  display: inline-block;
  height: 0.5em;
  align-self: flex-end;
  margin-bottom: 5px;
  &.defaultWidth {
    width: 98%;
  }
  &.customWidth10 {
    width: 10%;
  }
  &.customWidth20 {
    width: 20%;
  }
  &.customWidth30 {
    width: 30%;
  }
  &.customWidth40 {
    width: 40%;
  }
  &.customWidth50 {
    width: 50%;
  }
  &.customWidth60 {
    width: 60%;
  }
  &.customWidth70 {
    width: 70%;
  }
  &.customWidth80 {
    width: 80%;
  }
  &.customWidth90 {
    width: 90%;
  }
  &.zeroPercent {
    :global(.bar) {
      display: none;
    }
  }
}
