.buttonWrapper {
  display: flex;
  flex-wrap: nowrap;
  font-size: 16px;
  .cancelButton {
    color: #000;
    cursor: pointer;
    margin-right: 15px;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
  }
}
