@import 'src/variables.scss';

.realtime {
  .header:global(.ui.card) {
    z-index: $zIndexHigh;
    margin-bottom: 0;
  }
}

.pill {
  display: inline;
}

.topRow {
  display: flex;
  &:global(.react-multi-carousel-list) {
    display: block;
    flex: 1;
  }

  @media screen and (min-width: 970px) {
    :global(.react-multi-carousel-item:first-child) {
      :global(.ui.card) {
        margin-right: 8px;
      }
    }
    :global(.react-multi-carousel-item:last-child) {
      :global(.ui.card) {
        margin-left: 8px;
      }
    }
  }
  :global(.ui.card) {
    margin-left: auto;
    margin-right: auto;
  }

  :global(.react-multi-carousel-track) {
    float: left;
  }
  :global(.react-multi-carousel-list) {
    > button {
      top: 44%;
      opacity: 0;
    }

    &:hover {
      > button {
        opacity: 1;
      }
    }
  }
}
