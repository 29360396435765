.sectionWrapper {
  max-width: 570px;
}
.welcomeImagesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: -14px;
  .imageConstraintsSingle {
    width: 33%;
    height: 112px;
    padding: 1px;
  }
  .imageConstraintsDual {
    width: 50%;
    height: 84px;
    padding: 1px;
  }
  .singleWrapper {
    position: relative;
    .singleImage {
      height: 105px;
      width: 100%;
      margin: 5px 5px 0px 0px;
      border: 1px solid grey;
      background-color: lightgrey;
      &.activeImage {
        border: 3px solid #bd10e0;
      }
      &.disabledImage {
        opacity: 0.5;
        -moz-opacity: 0.5; /* Firefox and Mozilla browsers */
        -webkit-opacity: 0.5; /* WebKit browser e.g. Safari */
        -khtml-opacity: 0.5;
        filter: alpha(opacity=50); /* For IE8 and earlier */
      }
    }
    .iconOverlaySingle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .plusIcon {
        color: #ffffff;
        opacity: 0.85;
        height: 40px;
        width: 40px;
      }
      .plusIcon:hover {
        cursor: pointer;
      }
      :global(.loader) {
        margin-top: -10px;
      }
    }
    .checkboxOverlay {
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  }
  .singleCheckbox {
    position: relative;
    bottom: 30px;
    left: 85%;
    .imageCheckbox {
      margin-left: 5px;
    }
  }
  // Dual output image settings

  .dualWrapper {
    position: relative;
    .dualImage {
      height: 80px;
      width: 270px;
      margin: 5px 5px 0px 0px;
      border: 1px solid grey;
      background-color: lightgrey;
      &.activeImage {
        border: 3px solid #bd10e0;
      }
      &.disabledImage {
        opacity: 0.5;
        -moz-opacity: 0.5; /* Firefox and Mozilla browsers */
        -webkit-opacity: 0.5; /* WebKit browser e.g. Safari */
        -khtml-opacity: 0.5;
        filter: alpha(opacity=50); /* For IE8 and earlier */
      }
    }
    .iconOverlayDual {
      position: absolute;
      bottom: 40px;
      margin-left: 120px;
      .plusIcon {
        color: #ffffff;
        opacity: 0.85;
        height: 40px;
        width: 40px;
      }
      .plusIcon:hover {
        cursor: pointer;
      }
      :global(.loader) {
        margin-top: -25px;
        margin-left: 15px;
      }
    }
  }
  .dualCheckbox {
    position: relative;
    bottom: 30px;
    left: 245px;
    .imageCheckbox {
      margin-left: 5px;
    }
  }
}
