@import 'src/variables.scss';

.allPodsButtons {
  margin-bottom: -16px;
  float: right;
}

.exclamationTriangle {
  color: $warning;
  position: relative;
  top: 3px;
}

.renew {
  [class*='allPods'] {
    [class*='tableHeaderWrapper'] {
      [class*='tableHeader'] {
          [class*='tableName'] {
            text-align: center;
          }
      }
    }
  }
  [class*='columnManager'] {
    width: 33%;
  }
}
