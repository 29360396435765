@import "src/variables.scss";

.podNameCard:global(.ui.card) {
  grid-column-start: 1;
  min-height: 250px;
  .nameVersions {
    .podName {
      overflow-wrap: break-word;
      white-space: nowrap;
      display: flex;
      .statusIcon {
        font-size: 18px;
        line-height: 24px;
        flex-shrink: 0;
      }
      h1 {
        font-size: 22px;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
      }
    }
    p,
    .macAddresses {
      margin-left: 26px;
      margin-bottom: 0;
      font-size: 12px;
    }
    p.versions {
      letter-spacing: 0;
      line-height: 19px;
    }
    .macAddresses {
      height: 51px;
      .label {
        font-weight: bold;
      }
      .copyIcon {
        width: 20px;
        height: 10px;
        fill: $blue;
        cursor: pointer;
      }
    }
  }
  .form {
    margin-top: 16px;
    @media (max-width: $md-max) {
      display: flex;
    }
    .textInput {
      padding-bottom: 0;
    }
    .cancelSave {
      margin-top: 24px;
      @media (min-width: $lg-min) {
        float: right;
        margin-top: 16px;
      }
      button {
        margin-right: 0;
      }
    }
  }
}
