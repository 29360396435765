@import 'src/variables.scss';

.editForm {
  display: inline-block;
  margin-top: -10px;
  margin-bottom: -6px;
  :global(.flatpickr-input) {
    border-radius: 4px;
    border: 1px solid $grey;
    height: 38px;
    margin: 4px;
    max-width: 132px;
    padding: 4px;
    &:global(.active) {
      border-color: $primary;
    }
  }
  span {
    font-size: 18px;
  }
}

.taskProgress {
  display: inline-flex;
  width: 70%;
  .taskPercent {
    font-size: 16px;
    font-weight: bold;
  }
}

.contentWrapper {
  .utcGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 15px;
    position: relative;
    .utcLabel {
      color: $primary;
      font-size: 9px;
      font-style: italic;
      font-weight: 900;
      left: 0;
      position: absolute;
      top: 0;
    }
    .podData {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      padding: 0 18px 10px 18px;
      width: 50%;
      .podName {
        font-size: 14px;
        width: 40%;
      }
      .progressSection {
        padding-top: 2px;
        position: relative;
        width: 60%;
        .message,
        .failMessage {
          font-size: 12px;
          font-style: italic;
          position: absolute;
          top: 10px;
          left: 10px;
        }
        .failMessage {
          top: 0px;
          left: 0px;
        }
        i {
          float: right;
        }
      }
      .percentage {
        font-size: 10px;
        padding-left: 8px;
      }
    }
  }
}

:global(p).confirmationHeader {
  margin-top: 25px;
  font-weight: 700;
}

.buttons {
  display: flex;
  margin-top: 2px;
  i {
    margin: 0 10px;
  }
}
