@import 'src/variables.scss';

.icon {
  min-width: 15px;
  display: inline-block;
  :global(.loader) {
    z-index: unset;
  }
}

.loading {
  margin-right: 5px;
}

.tooltip:global(.ui.popup.transition.visible) {
  margin-left: -12px;
}
