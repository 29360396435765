@import 'src/variables.scss';

.card:global(.ui.card) {
  margin-left: 8px;
  margin-right: 8px;
  width: 205px;
  @media (max-width: $lg-max) {
    margin-right: auto;
  }
  .title {
    text-align: center;
  }
  :global(.legend) {
    margin-top: 16px;
  }
}
