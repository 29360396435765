@import 'src/variables.scss';

.solTabs {

  :global(.ui.secondary.pointing.menu .active.item) {
    border-bottom: 2px solid $primary;
  }
  :global(.ui.secondary.pointing.menu .item) {
    font-weight: 500;
  }


  :global(.ui.secondary.pointing.menu),
  :global(.ui.attached.segment),
  :global(.ui.segment.active.tab) {
    border: none;
    box-shadow: none;
    webkit-box-shadow: none;
  }

  &.tabs-center {
    :global(.ui.secondary.pointing.menu a:first-child) {
      margin-left: auto;
    }
    :global(.ui.secondary.pointing.menu a:last-child) {
      margin-right: auto;
    }

  }

  &.tabs-left {
    //Nothing to do here so far
  }

  &.tabs-right {
    :global(.ui.secondary.pointing.menu a:first-child) {
      margin-left: auto;
    }
    :global(.ui.secondary.pointing.menu a:last-child) {
      margin-right: 10px;
    }
  }
}
