.enabledWrapper {
  min-width: 200px;
  .eapWrapper {
    margin-left: 0;
  }
  .block {
    display: block;
    width: 100%;
    margin-bottom: 16px;
  }
  .phase2Options {
    display: block;
    width: 100%;
    .phase2 {
      font-size: 15px;
      margin-bottom:5px;
      font-weight: bold;
    }
  }
}
