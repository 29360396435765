@import 'src/variables.scss';

.chartContainer {
  :global(.ui.loader) {
    z-index: $zIndexLow;
  }
  :global(.ui.header).header {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
.link {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

:global(.ui.card).card {
  height: 255px !important;
  width: 450px !important;
}
