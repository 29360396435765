.wrapper {
  margin-top: 1em;
  
  .fixedWidth {
    width: 250px;
  }
  .widthSize {
    min-width: 300px;
  }
}

.requirement {
  display: flex;
  i {
    flex-shrink: 0;
  }
}
