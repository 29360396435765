.proxyOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: flex-start;
}

.subnetCheckbox {
  margin-bottom: 24px;
}