.customDropdown {
  display: inline-block;
  height: 38px;
  width: 196px;
  margin-right: 10px;
  margin-left: 10px;
}

.dateDropdown {
  max-height: 15.5rem;
}

@media only screen and (min-width: 480px) {
  .customDropdown .ui.selection.active.dropdown .menu {
    max-height: 15.5rem !important;
  }
}
