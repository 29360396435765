@import 'src/_variables';

.header {
  display: flex;
  justify-content: space-between;
  :global(.ui.header .sub.header).subHeader {
    color: black;
    font-weight: 400;
    font-size: 14px;
    padding-top: 5px;
    margin-bottom: 8px;

    label {
      font-weight: normal;
    }
  }
}

.filterSection {
  display: flex;
}

.filterButton {
  margin-right: 8px;
}

:global(.ui.card).solCard {
  border: 1px #f8f8f8 solid;
  border-radius: 4px;
  margin: 0px;
  margin-bottom: 16px;
  width: 100%;
  min-height: 117px;
  z-index: 599;
  padding: 0px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  &.shadow {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.row {
  display: flex;
}

.column {
  flex: 1;
}

.mobility {
  display: flex;
  @media (max-width: $md-max) {
    flex-direction: column;
  }
}

.most-collaborative-spaces {
  flex: 1;
  height: 100%;
}

.elementMessageContainer {
  padding: 0 30px 20px 0;
}
