@import 'src/variables.scss';
.chart-container {
  position: relative;
}

.dropdown-container {
  position: absolute;
  right: 100px;
  top: 20px;
  z-index: $zIndexMedium;
}

.options {
  margin-bottom: 20px;
}