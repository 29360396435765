@import 'src/variables.scss';

.dropdownContainer,
.tooltip {
  padding: 0;
  align-self: flex-end;
  vertical-align: middle;
  flex-shrink: 0;

  .dropdown {
    display: flex;
    padding: 4px 0;

    .trigger {
      flex-shrink: 0;
      color: $primary;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      width: 136px;

      .icon {
        height: 15px;
        vertical-align: middle;
        margin-right: 2px;
      }
    }

    i:global(.caret.down.icon) {
      position: relative;
      color: $primary;
      margin-right: 0;
    }

    :global(.menu) {
      z-index: $zIndexHigh;
    }
  }
}

.podPaginationButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.podImage {
  width: 200px;
  padding: 0.8em;
}

.podEventDetail {
  padding: 0.2em;
  border: 1px dashed darkgray;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
}

.podEventDetailField {
  display: flex;
  flex-direction: row;
}

.podEventData {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.podDetails {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.4em;
  width: 100%;
  min-width: 100%;

  .label {
    padding-left: 0.2em;
    font-weight: bold;
    min-width: 100px;
    text-align: left;
  }

  p {
    display: block;
    font-size: 0.7em;
  }
}

.downgradeModal {
  width: 80%;
  margin: 0 30px;
}

.downgradeModalHeader {
  overflow-wrap: break-word;
}

.downgradeButtonDiv {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.downgradeCancelButton {
  padding-right: 50px;
  box-shadow: none !important;
}

.releasesDropDown {
  margin: auto !important;
  font-size: 1rem !important;
  z-index: 501 !important;
}

.smallText {
  padding: 20px 0;
  font-size: .72em;
  text-align: left;
}

.deletePodText {
  color: #BF1244;
}
