.header {
  display: flex;
  .left-col {
    flex: 1;
    margin-right: 20px;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
}
