@import 'src/variables.scss';
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zIndexHighest;
  background-color: rgba(0, 0, 0, 0.45);
}

.modalContent {
  outline: none;
  text-align: left;
  border-radius: 4px;
  width: 352px;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 20px;
  background-color: white;
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  margin: 10% auto 0 auto;
  .modal {
    padding: 24px;
    line-height: 24px;
    .modalText {
      overflow-wrap: break-word;
      font-weight: 900;
      margin-bottom: 0;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .modalButton {
      font-size: 16px;
      margin-top: 10%;
      align-items: center;
      justify-content: flex-end;
      display: flex;
    }
  }
}

.subHeaderText {
  font-size: 14px;
  padding-top: 24px;
  line-height: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}
