.header {
  padding: 16px 0;
  font-size: 20px;

  .grey {
    color: grey;
  }
  .icon {
    margin-left: 5px;
  }
}
.accessHeader {
  font-size: 20px;
}

.revokeAccess {
  margin-left: 20px;
}