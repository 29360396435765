@import 'src/variables.scss';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.buttonGroup{
  padding: 0px;
}

.calendarContainer {    
  width: 80%;
  font-size: 0;
  height: 0;
  line-height: 0;

  input {
    border: 0;
    color: #2185d0;
    outline: 0;
    text-align: center;
    width: 100%;
  }

  :global(.flatpickr-input) {
    visibility: hidden;
  }
}

:global(.flatpickr-calendar.calendarChartHeader) {
  &:after,
  &:before {
    border-width: 0;
  }
}

:global(.calendarChartHeader .flatpickr-day) {
  &:global(.startRange.selected) {
    pointer-events: none;
  }
}

.icon {
  fill: $primary;
  height: 30px;
  width: 30px;
  cursor: pointer;
  
  &.disabled {
    fill: $grey;
    pointer-events: none;
  }
}

.indicators {
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 30px; 
  margin-top: 10px;

  &.calendar-active {
    color: #2185d0;

    .icon {
      display: none;
    }
  }
}

.calendar-hide {
  height: 0;
  overflow: hidden;
}