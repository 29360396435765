.progress-bar {
  position: relative;
  padding-top: 23px;

  & .progressBarContainer:global(.ui.progress) {
    visibility: visible;
    border-radius: 2px;

    :global(.bar) {
      min-width: 0;
      border-radius: 2px;
      background: linear-gradient(90deg,
          #4d9b32 0%,
          #ffe34e 137.62%,
          #b00027 255.17%);
    }
  }

  .label {
    position: absolute;
    top: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: center;
    display: inline-block;
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #000;
    display: inline-block;
    vertical-align: top;

    position: absolute;
    top: 15px;
  }
}