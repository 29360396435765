:global(.ui.card).card {
  min-width: 350px;
  margin-top: 1px;
  height: 370px;
  :global(.content) {
    padding: 24px;
  }
  :global(.header) {
    font-size: 20px;
  }

  :global(.legend) {
    position: relative;
    top: 20px;
    padding-right: 0px;
  }
}
