@import 'src/variables.scss';

.mapCard:global(.ui.card) {
  height: 545px;
  width: 100%;
  z-index: $zIndexMedium;
  @media (min-width: $lg-min) {
    grid-column-start: 2;
    grid-row: 1 / span 2;
  }
  > :global(.content) {
    > :global(.header) {
      display: flex;
      .header {
        h2 {
          margin-bottom: 0;
        }
        p.address {
          color: $darker-grey;
          font-size: 16px;
          font-weight: normal;
        }
        flex-grow: 1;
        padding-right: 8px;
      }
      .mapButtons {
        display: inline-block;
        line-height: 48px;
        flex-shrink: 0;
        .button {
          margin-right: 10px;
        }
      }
    }
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .borderActive {
      align-self: stretch;
      flex-grow: 1;
      margin-top: 12px;
      margin-bottom: 12px;
      padding: 0px;

      border: 4px solid #66cc33;
    }
    .border {
      align-self: stretch;
      flex-grow: 1;
      margin-top: 12px;
      margin-bottom: 12px;
      padding: 0px;
      border: 4px solid rgba(0, 0, 0, 0);
    }
    .infoActive {
      z-index: $zIndexMedium;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      text-align: center;
      padding: 1% 0;
      height: 40px;
      width: 75%;
      font-size: 1.1em;
      position: absolute;
      top: 0%;
      left: 12.5%;
    }
    .displayNone {
      display: none;
    }
    .savedBuildingActive{
      background-color: #2db84b;
      color: white;
      text-align: center;
      width: 60%;
      min-height: 27px; 
      border-radius: 5px; 
      z-index: $zIndexMedium;
      position: absolute;
      top: 2%;
      left: 20%;
      padding: .5% 0;
      margin: 0 auto;
    }
    .map {
      height: 100%;
      min-height: 300px; // for safari - smallest the map gose with  height: 100%;
      width: 100%;
      z-index: $zIndexLow;
    }
    .location {
      display: grid;
      grid-template-columns: 275px 138px repeat(2, 1fr);
      grid-column-gap: 8px;
      .input {
        padding-bottom: 0;
        margin-top: -1px;
      }
      .floorsDropdown {
        min-width: unset;
      }
    }
    .controlButtonsOut {
      z-index: $zIndexLow;
      right: 13px;
      top: 13px;
      position: absolute;
      background-color: #fff;
      padding: 0px 2px;
      padding-top: 2px;
      margin: 0px;
      border-radius: 4px;
      border: 1.5px solid rgba(0, 0, 0, 0.25);
      filter: drop-shadow(-2px 1px 2px rgba(0, 0, 0, 0.25)); 
      &:hover {
        background-color: rgb(228, 226, 226);
        cursor: pointer;
      }
    }
    .targetIcon {
        height: 18px;
        width: 18px; 
        filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0)); 
      }
    .dotTooltip {
      margin-top: 20px;
      height: 24px;
      line-height: 10px;
      background-color: rgba(0, 0, 0, 0.6);
      color: white;
      border: 2px solid rgba(0, 0, 0, 0.6);
    }
    .gps{
      z-index: $zIndexMedium; 
    }
    .podDot{
      z-index: $zIndexLow; 
    }
  }
  :global(.leaflet-control-zoom.leaflet-bar.leaflet-control) {
    margin-right: 13px;
    margin-top: 44px;
    z-index: $zIndexLow;
  }
  :global(.leaflet-tooltip.leaflet-zoom-animated.leaflet-tooltip-bottom::before) {
    display: none;
  }
  .setLocationModal {
    .dropDown{
      width: 100%;
    }
    .nextButton {
      margin-right: 0;
    }
  }
}
