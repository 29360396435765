@import 'src/_mixins';
:global(.ui.card).solCard {
  border: 1px #f8f8f8 solid;
  border-radius: 4px;
  margin: 16px 8px;
  * {
    @include light-scrollbar;
  }
  > :global(.content) {
    padding: 14px;
    > :global(.header) {
      color: #000000;
      font-family: Lato;
      font-weight: 700;
    }
  }
  &.shadow {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}
