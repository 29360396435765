@import 'src/variables.scss';

.stickyBanner {
  min-height: 50px;
  height: auto;
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 16px;
  text-align: center;

  &.warning {
    background-color: $warning;
  }

  &.grey {
    background-color: $secondary;
  }

  &.error {
    background-color: $error;
  }

  &.green {
    background-color: $mersive-green;
  }
}

.exclamationTriangle {
  position: relative;
  top: 5px;
  left: -5px;
}
