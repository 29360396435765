.podTableID {
  border: none;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.podTableHead {
  /* head takes the height it requires,
    and it's not scaled when .staticTable.ui.table is resized */
  flex: 0 0 auto;
  width: calc(100% - 0.9em);
  font-weight: 700;
}

.podTableBody {
  /* body takes all the remaining available space */
  flex: 1 1 auto;
  display: block;
}

.podTableData {
  padding: 10px 0;
  min-height: 1em;
}

.podTableData:first-child {
  width: 220px;
}

.podTableRow:first-child {
  border-top: 1px solid rgba(62, 107, 151, 0.1);
}

.podTableHead {
  display: table;
  table-layout: fixed;
}

.podTableBody .podTableRow {
  display: table;
  table-layout: fixed;
  width: 100%;
}