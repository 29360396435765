@import "src/variables.scss";

:global(.ui.vertical.menu).menu {
  background-color: $nav-secondary;
  border-radius: 0;
  border: none;
  width: 208px;
  :global(.content.active) {
    padding: inherit;
  }
  .item:first-child {
    padding-top: 28px;
  }
  .item:last-child {
    padding-bottom: 0;
  }
  .item {
    padding: 14px 0 14px 12px;
    color: $secondary;
    font-size: 15px;
    background-color: $nav-secondary;
    &:hover {
      // overwriting semantic hover states
      background-color: $nav-secondary;
      color: $secondary;
    }
    &:global(.active.item) {
      color: $nav-primary;
      > .icon {
        fill: $nav-primary;
        path {
          fill: $nav-primary;
        }
      }
      > .accordionTitle > .menuArrow {
        transform: rotate(90deg);
      }
    }
    .icon {
      float: left;
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-right: 12px;
      fill: $secondary;
    }
  }
  .childItem {
    padding: 10.5px 0 10.5px 24px;
    color: $secondary;
    font-size: 14px;
    background-color: $nav-secondary;
    &:hover {
      // overwriting semantic hover states
      background-color: $nav-secondary;
      color: $secondary;
    }
    &:global(.active.item) {
      color: $nav-primary;
      > .icon {
        fill: $nav-primary;
      }
      > .accordionTitle > .menuArrow {
        transform: rotate(90deg);
      }
    }
    .icon {
      float: left;
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-right: 12px;
      fill: $secondary;
    }
    .childContent {
      margin-left: -10px !important;
      :global(.content.active) {
        padding: inherit;
        margin-left: 0;
      }
    }
    .grandchildItem {
      padding: 6.5px 0;
      color: $secondary;
      font-size: 12px;
      background-color: $nav-secondary;
      :global(.content.active) {
        padding: inherit;
        margin-left: 0;
      }
      &:hover {
        // overwriting semantic hover states
        background-color: $nav-secondary;
        color: $secondary;
      }
      &:global(.active.item) {
        color: $nav-primary;
        > .icon {
          fill: $nav-primary;
        }
        .menuArrow {
          transform: rotate(90deg);
        }
      }
      .icon {
        float: right;
        width: 20px;
        height: 20px;
        margin-top: -2px;
        fill: $secondary;
      }
    }
    :global(.content.active) {
      margin-left: 0px;
    }
  }
  .expandedAccordion {
    padding-bottom: 0;
  }
  .accordionTitle {
    border: none;
    color: $secondary;
    margin: 0;
    i:global(.dropdown.icon) {
      display: none;
    }
    &.nested {
      width: 90px;
    }
    .menuArrow {
      float: right;
      width: 20px;
      height: 20px;
      fill: $secondary;
      margin-top: -2px;
      margin-right: -6px;
      transition: transform 0.2s ease;
      &.child {
        margin-right: -21px;
      }
    }
    &:global(.active) {
      .icon {
        fill: $nav-primary;
      }
      .menuArrow {
        fill: $nav-primary;
      }
    }
  }
  :global(.ui.accordion:not(.styled)
      .title
      ~ .content:not(.ui):last-child, .ui.accordion:not(.styled)
      .title
      ~ .content:not(.ui):last-child) {
    padding: 0 0 0 0;
  }
}

:global(.ui.vertical.menu .item > i.icon) {
  float: unset;
}

.svgMenuIcon {
  margin-top: -12px;
  margin-bottom: -3px;
  margin-left: 2px;
}