
.ethernetEnabledWrapper {
  min-width: 200px;
  .ethernetEnabled {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  .networkName {
    margin-left: 28px;
    display: block;
    .networkInformation {
      display: inline-block;
    }
  }
}


@media(max-width: 991px) {
  .ethernetEnabledWrapper {
    margin-bottom: 10px;
  }
}
