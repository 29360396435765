@import 'src/variables.scss';

.fixBluePillNotification {
  position: absolute;
  top: 4px;
  right: 0;
}

.uniqueSetting {
  // display: inline;
  position: relative;
  min-height: 75px;
  margin: 0 4px 8px 0;
  width: fit-content;
  &.tiny {
    width: 88px;
  }
  &.small {
    width: 138px;
  }
  &.defaultMedium {
    width: 184px;
  }
  &.large {
    width: 280px;
  }
  &.inline {
    min-height: 0;
    top: -44px;
  }
  .float,
  .disabled {
    position: absolute;
    left: -6px;
    right: -4px;
    flex-direction: row;
    height: 75px;
    padding: 5px;
  }
  .float {
    border: 1px solid #ff3366;
    background-color: white;
    padding: 4px 0 0 4px;
    &.active {
      z-index: $zIndexMedium;
      &:focus-within {
        z-index: $zIndexHigh;
      }
      height: unset;
    }
    .saveCancel {
      padding: 3px;
      .cancel {
        float: left;
      }
      .save {
        float: right;
      }
      .button {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}
