@import 'src/variables.scss';

.httpEnabled,
.httpDisabled {
  max-width: 455px;
  margin-left: 32px;
  border-right: 1px solid #e5e5e5;
  min-height: 300px;
  .proxyColumn {
    height: 335px;
    margin-right: 50px;
    margin-left: 26px;
    min-width: 495px;
  }
}

.httpsColumn {
  max-width: 460px;
  margin-left: 52px;
  min-height: 300px;
  .proxyColumn {
    height: 335px;
    margin-left: 26px;
  }
}

@media screen and (max-width: 1200px) {
  .httpEnabled {
    max-width: 100%;
    border-right: none;
    min-height: 0px;
    border-bottom: 1px solid #e5e5e5;
    .proxyColumn {
      height: auto;
      margin-right: 0px;
    }
  }
  .httpDisabled {
    max-width: 100%;
    border-right: none;
    min-height: 0px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 16px;
    .proxyColumn {
      height: auto;
      margin-right: 0px;
    }
  }
  .httpsColumn {
    margin-left: 32px;
    min-height: 0px;
    .proxyColumn {
      height: auto;
      margin-left: 26px;
    }
  }

  .enabledCheckbox {
    margin-bottom: 16px
  }
}