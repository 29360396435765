:global(.fields) {
  justify-content: flex-start;
  align-items: flex-end;
}

:global(.segment) {
  padding: '24px 32px';
}

.emailAddress {
  width: '24rem';
  margin-right: '12px';
}

.inputContainer {
  display: 'flex';
  width: '100%';
}

.error {
  color: '#d86868';
}

.email {
  width: '350px';
}

.roles {
  width: 275px;
  height: 61px;
  .helpCircleIcon {
    position: absolute;
    fill: #2987cd;
    height: 16px;
    cursor: pointer;
  }
}

.helpCircleIcon {
  fill: #2987cd;
  height: 16px;
  cursor: pointer;
}

.rolesModal {
  font-size: 12px !important;
  width: 630px !important;
  min-height: 220px !important;
  :global(.ui.table) th {
    border-bottom: 0;
  }
}
.checkbox {
  padding: 0px 0px 10px 16px;
  display: inline-block;
}

:global(.ui.selection.dropdown),
:global(.ui.form .field .ui.selection.dropdown) {
  :global(.active > .text) {
    font-weight: 600;
  }
  :global(.menu) {
    :global(.item) {
      display: flex;
      flex-direction: column-reverse;
      padding: 10px 15px;
      :global(.text) {
        font-weight: 600;
      }
      :global(.description) {
        font-size: 10px;
        color: black;
        margin: 0;
        float: none;
      }
    }
  }
}
