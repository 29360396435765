.parent {
  margin-top: -16px;
  .ctaContainer {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .cta {
    margin: 20px;
    margin-left: 0;
  }
  .carousel {
    flex: 1;
    :focus {
      outline: none;
    }
  }
  .title {
    line-height: 50px;
    font-size: 16px;
    font-weight: 0;
    text-align: center;
  }
  .carouselWrapper {
    margin-top: 25px;
    margin-bottom: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 2;
    :focus {
      outline: none;
    }
  }
}
