@import "src/variables";

.inputGroup {
  display: inline-block;
  padding: 0px 0px 16px 0;
  .hidePassword {
    position: absolute;
    top: 10px;
    color: $primary;
    right: 6px;
    font-size: 12px;
    font-weight: 500;
  }
  .hidePassword:hover {
    cursor: pointer;
  }
  .small {
    width: 88px;
    .showPassword:hover {
      cursor: pointer;
    }
  }
  .defaultMedium {
    width: 184px;
    :global(i.icon).showPassword:hover {
      cursor: pointer;
    }
  }
  .large {
    width: 280px;
    .showPassword:hover {
      cursor: pointer;
    }
  }
  &.disabled {
    label {
      color: grey;
    }
  }
  &.fluid {
    width: 100%;
  }
  label {
    font-weight: 700;
    font-size: 15px;
    display: block;
    padding-bottom: 3px;
    .optionalInput {
      font-weight: 400;
    }
    .required {
      padding-left: 4px;
      color: red;
    }
  }
  .errorText {
    height: 16px;
    color: #9f3a38;
    font-size: 12px;
    font-weight: 500;
  }
}
.noBackgroundHighlight {
  :global(.ui.input.error > input) {
    background-color: transparent;
    border-color: rgba(34, 36, 38, 0.15);
  }
}
