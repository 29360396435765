@import 'src/variables.scss';

.colorBox {
    height: 35px;	
    width: 35px;	
    border: 0px solid #1B1C1D;	
    border-radius: 4px;	
    background-color: #FFFFFF;
    position: relative;
    top: 5px;
    display: inline-block;
}
.colorBoxArrow {
    transform: rotate(90deg);
    fill: #1279C6;
}
.picker {
    position: absolute;
    // bottom: 15%;
    z-index: 1001;

    :global(.github-picker ),
    :global(.github-picker ) * {
        z-index: 1001;
    }
}
.clickOut {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zIndexHigh;
}
