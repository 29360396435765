@import 'src/variables.scss';

.buttonGroup {
    display: inline-block;
    padding: 0 8px 16px 0;
    label {
        display: block;
        font-family: Lato;	
        font-size: 15px;	
        font-weight: bold;	
        line-height: 18px;
        padding-bottom: 8px;
    }

    :global(.ui.button){
        background-color:#FFFFFF;
        color:#5A5A5A;
        border: #B5B5B5 .5px solid !important;  //ui  override semtatic
        &:hover{
          background-color: #D7EAF8;
        }
        
      }
    :global(.ui.disabled.button){
        background-color:#FFFFFF;
        color:#B5B5B5;
        border: #B5B5B5 .5px solid !important;  //ui  override semtatic
       
      }
    :global(.ui.primary.button){
        border: $primary .5px solid !important; //ui  override semtatic
        color: $primary !important; //ui  override semtatic
        background-color: #D7EAF8 !important; //ui  override semtatic
      }

}