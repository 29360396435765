.layout_container {
  aspect-ratio: 9/6.0;
}

.gradientRectStop1 {
  stop-color:#455C86;
  stop-opacity:1
}

.gradientRectStop2 {
  stop-color:#31394E;
  stop-opacity:1
}