@import 'src/variables.scss';
.ui {
  &.blue.basic.label, &.teal.basic.label {
    background-color: white !important;
  }
}

.dropdown{
  z-index: $zIndexMedium ;
}
.parent {
  display: flex;
  margin-bottom: 10px;
  margin-left: 80px;

}

.secondary {
  margin-left: 15px
}

.elementMessageContainer {
  margin-left: auto;
  padding-right: 80px;
}
