@import "src/variables";
.inputGroup {
  display: inline-block;
  padding: 0px 8px 16px 0;
  input {
    text-overflow: ellipsis;
    overflow: hidden;
    &.inlineSave {
      padding-right: 48px;
    }
  }
  .tiny {
    width: 88px;
  }
  .small {
    width: 138px;
  }
  .defaultMedium {
    width: 184px;
  }
  .large {
    width: 280px;
  }
  .extraLarge {
    width: 375px;
  }
  &.disabled {
    label {
      color: grey;
    }
  }
  &.unclickable {
    opacity: 1;
    label {
      color: black;
    }
    :global(.ui.disabled.input) {
      opacity: 1;
    }
  }
  .saveButton {
    position: absolute;
    top: 2px;
    color: $primary;
    right: 0;
    font-size: 12px;
    font-weight: 500;
    max-width: 62px;
    &:hover {
      cursor: pointer;
    }
  }
  &.fluid {
    width: 100%;
    padding-right: 0;
  }
  label {
    font-weight: 700;
    font-size: 15px;
    display: block;
    padding-bottom: 3px;
    .optionalInput {
      font-weight: 400;
    }
    .required {
      padding-left: 4px;
      color: red;
    }
    .icon {
      padding-left: 10px;
    }
  }
  .errorText {
    height: 16px;
    padding-left: 16px;
    color: #9f3a38;
    font-size: 12px;
    font-weight: 500;
  }
}
.noBackgroundHighlight {
  :global(.ui.input.error > input) {
    background-color: transparent;
    border-color: rgba(34, 36, 38, 0.15);
    color: rgba(34, 36, 38, 0.25);
  }
  :global(.ui.input.error > input::placeholder) {
    color: rgba(34, 36, 38, 0.25);
  }
}

.syncStateIcon {
  margin-left: 3px;
}
