$color-teal: #3BB6C5;
$color-blue: #1279C6;
$color-dark-grey: #333333;
$color-white: #FFFFFF;

:global(.ui.segment).box {
  align-self: center;
  height: 186px;
  width: 290px;
  background-color: $color-white;
  color: $color-blue;
  text-align: center;
  margin-bottom: 14px;
  margin-top: 14px;
  margin-left: 16px;

  &.live {
    background-color: $color-blue;
    color: $color-white;
    margin-left: 0px;
  }

  a {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
}

.metric {
  font-size: 75px;
  line-height: 90px;
}

.unit {
  font-size: 30px;
  font-weight: 300;
  padding-left: 5px;
  line-height: 0px;
}
.caption {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: $color-dark-grey;

  &.live {
    color: $color-white;
  }
}
