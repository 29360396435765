@import 'src/variables.scss';

.sso {
  position: relative;
}

.header {
  padding: 16px;
  font-size: 20px;

  .grey {
    color: grey;
  }

  i {
    margin: 0 10px;
  }

  .helpTooltip {
    margin-left: -5px;

    .questionIcon {
      height: 24px;
      width: 24px;
      position: relative;
      right: -8px;
      top: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.instructions{
  max-width: 650px;
  margin-left: 28px;
  margin-bottom: 30px;
}
.signOnURL {
  overflow: hidden;
  text-overflow: ellipsis;
}
:global(.ui.card).ssoCard {
  width: 100%;
  margin: 0;
  padding-bottom: 5em
}

.ssoCard {
  .cardHeader {
    margin: 5px;
    width: 98%;
    max-height: 60px;
    display: inline-flex;
    justify-content: space-between;

    .leftContent {
      display: inline-flex;
      justify-content: flex-start;
      min-width: 33%;
      font-size: 18px;
      line-height: 25px;
      margin-right: 8px;
      padding-top: 7px;
      padding-left: 7px;

    }

    .rightContent {
      display: inline-flex;
      justify-content: flex-end;
      min-width: 66%;

      .actionButtons {
        display: flex;
      }
    }

  }

  .cardHeader:after {
    padding: 0px;
    margin: 1px;
    content: " ";
    display: block;
    position: absolute;
    background: $light-grey;

    height: 2px;
    width: 96%;
    top: 9%;
  }

  .body {
    margin: 7px;
    padding: 1em;
    display: flex;
    
    label {
      font-weight: 700;
      font-size: 14px;
      display: flex;

      .required {
        padding-left: 4px;
        color: red;
      }
    }
    .inputSection, .displaySection {
      flex: 1;
      min-width: 370px;
    }

    .inputSection {
      padding-right: 3em;
    }

    .error {
      margin: 0px;
      padding: 0px;
      color: $error;
    }

    .section {
      padding-bottom: 2em;
    }

    .input {
      padding-bottom: 1.5em;
    }
  }
  .placeHolder {
    color: lightgray;
  }
}
.attributeLabel {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}
.attributeHeader {
  margin-bottom: 3px;
}
.icon {
  padding-left: 4px;
}
@media (max-width: 1000px) {
  .body {
    flex-direction: column;
  }
}
.required{
  color: red;
}
.attributesSection{
  display: flex;
}
.attributeHeader{
  margin-top: 0px;
  margin-bottom: 12px;
}