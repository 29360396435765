@import "src/variables.scss";

.settingsCards {
  margin-top: 16px;
  margin-bottom: 32px;
  display: grid;
  @media (min-width: $lg-min) {
    grid-template-columns: 314px auto;
  }
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  :global(.ui.card > .content) {
    padding: 16px;
  }
  :global(.ui.card) {
    margin: 0;
    width: 100%;
  }
}
