@import 'src/variables.scss';

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 16px;
  margin-right: 56px;
  max-height: 40px;
  display: inline-flex;
  justify-content: space-between;

  .leftContent {
    display: inline-flex;
    justify-content: flex-start;
    min-width: 33%;
    font-size: 18px;
    line-height: 25px;
    margin-right: 8px;

    span {
      margin-right: 8px;

      i:global(.icon.small.circle) {
        position: absolute;
        top: 6px;
      }
    }

    .titleInput>input {
      margin-left: -8px;
      margin-top: -8px;
      height: 32px;
      border: none;
      padding: 8px;
      font-weight: bold;

      &:focus {
        border: 1px solid $primary;
      }
    }

    .header {
      display: flex;

      .templateName {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .assignedPods {
        font-weight: normal;
        margin-left: 12px;
        margin-right: 16px;
        flex-shrink: 0;
      }


    }

    .assignPods {
      white-space: nowrap;

      .button {
        display: inline-block;
        margin-top: -8px;
        flex-shrink: 0;
      }
    }

    .templateNameDropdown {
      border: 0;
      margin-top: -10px;
      width: 14em;
      display: flex;

      &:global(.active) {
        z-index: $zIndexMedium;
      }

      :global(.caret.icon) {
        color: $primary;
      }

      :global(.text) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.unassigned> :global(.text) {
        font-style: italic;
      }
    }

    .customTemplateName {
      font-style: italic;
    }
  }

  .centerContent {
    display: inline-flex;
    justify-content: center;
    position: relative;

    .cancelPending {
      border-radius: 0.28571429rem;
      background-color: white;
      position: relative;
      margin-top: -5px;
      z-index: $zIndexLow;

      :global(.button) {
        margin-right: 0;
      }
    }
  }

  .rightContent {
    display: inline-flex;
    justify-content: flex-end;
    min-width: 33%;

    :global(.ui.dropdown) {
      margin: 4px 16px 0 24px;
    }

    .actionButtons {
      display: flex;
    }
  }

  .uniqueDefault {
    font-weight: bold;
    font-style: italic;
  }

  .exclamationTriangle {
    color: $warning;
    position: relative;
    top: 3px;

  }

  :global(.ui.primary.button).editButton {
    position: relative;
    z-index: $zIndexMedium;
  }
}

.templateNameModalContent {
  text-align: left;
  width: 224px;
  margin: 16px auto -10% auto;

  :global(.ui.form .field > label) {
    font-size: 15px;
  }

  :global(.ui.form .field > div) {
    width: 100%;
  }
}

.unknownPasswordModalContent {
  margin-top: 24px;
  font-size: 14px;
}
