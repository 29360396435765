.loader {
  vertical-align: text-bottom;
  margin-left: 8px;
}

.elementVersionContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}
