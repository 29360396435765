
.routeCircle {
  --startX: 200;
  --startY: 200;
  --endX: 300;
  --endY: 300;
  --animationPeriod: '1s';
  --delay: '1s';
  animation-name: circleSlide;
  animation-duration: var(--animationPeriod);
  animation-delay: var(--delay);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes circleSlide {
  from {
    cx: var(--startX);
    cy: var(--startY);
  }
  to {
    cx: var(--endX);
    cy: var(--endY);
  }
}

@keyframes strokeDashOffsetSlide {
  from {
    stroke-dashoffset: 0
  }
  to {
    stroke-dashoffset: -15
  }
}