.templateWrapper {
  margin: 0 16px 16px 16px;
  .sdsHosts {
    max-width: 739px;
    margin-left: 26px;
  }
  .spanMargin {
    margin-top: 20px;
  }
}
