.scatter-marker div {
  background-color: rgba(22, 50, 150, 0.5);
  z-index: 400;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.scatter-marker>div {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  top: 0;
  left: 0;
}

.scatter-marker-1>div {
  width: 34px;
  height: 34px;
}

.scatter-marker-2>div {
  width: 46px;
  height: 46px;
}

.scatter-marker-3>div {
  width: 62px;
  height: 62px;
}

.leaflet-pane .scatter-marker.leaflet-marker-icon.leaflet-interactive {
  width: 62px !important;
  height: 62px !important;
  margin-left: -31px !important;
  margin-top: -31px !important;
}