.noBoxShadow {
  :global(.ui.basic.black.button),
  :global(.ui.basic.blue.button),
  :global(.ui.basic.green.button),
  :global(.ui.basic.button) {
    box-shadow: none !important;
    -webkit-box-shadow: none !important; // needed to use !important to override a semantic !important
  }
}

.linkButton {
  :global(.ui.basic.black.button),
  :global(.ui.basic.blue.button),
  :global(.ui.basic.green.button),
  :global(.ui.basic.button) {
    box-shadow: none !important;
    font-size: 14px;
    text-transform: capitalize;
    -webkit-box-shadow: none !important; // needed to use !important to override a semantic !important
  }
  :global(.ui.basic.button:active),
  :global(.ui.basic.black.button:active),
  :global(.ui.basic.blue:active) {
    background: none !important;
    box-shadow: none;
    font-size: 14px;
    text-transform: capitalize;
    -webkit-box-shadow: none;
  }
}
