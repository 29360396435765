.parent {
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * {
    margin: 0 2rem;
  }
}

.truncateCategory {
    display: inline-block;
    max-width: 90%;
    line-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.added-category-chart-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .added-category-title {
    line-height: 50px;
    font-size: 16px;
    font-weight: 00;
    text-align: center;
  }
}
