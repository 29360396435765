.wrapper {
  padding-top: 0;
  margin-left: 16px;
  padding-bottom: 0px;
  .pill {
    vertical-align: middle;
    &.gen3,
    &.calendar {
      margin-left: 0;
    }
  }
  .questionTooltip {
    margin-right: 0;
    vertical-align: middle;
  }
  .timeBeforeMeeting {
    width: 210px;
    max-width: unset;
  }
  .children {
    margin-left: 26px;
    .secondaryMessage {
      margin-top: 8px;
    }
  }
  .spanMargin {
    margin-top: 20px;
  }
}
