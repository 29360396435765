.popup {
  &.isInline {
    display: inline-block;
    margin-right: 5px;
  }
  &.isInlineThin {
    display: inline-block;
  }
}

.note {
  margin-top: 16px;
  font-weight: bold;
}
