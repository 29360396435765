@media screen and (min-width: 1201px) {
  .welcomePreview {
    .flashing {
      animation-duration: 1s;
      animation-name: flash;
    }

    @keyframes flash {
      from {
        background-color: rgba($color: #ffffff, $alpha: 0);
      }

      50% {
        background-color: rgba($color: #ffffff, $alpha: 1);
      }

      to {
        background-color: rgba($color: #ffffff, $alpha: 0);
      }
    }

    max-width: 100%;
    max-height: 100%;
    height: 321px;
    width: 572px;
    border: 1px solid grey;
    background-color: lightgrey;
    margin-bottom: 8px;
    position: relative;
    color: white;

    .presenceBar {
      background-color: #1b1c1d;
      z-index: 400;
      bottom: 0;
      width: 100%;
      opacity: 0.7;
      height: 20px;
      position: absolute;

      .pbRoomName {
        padding: 1px;
        display: inline-block;
      }

      .ipAddress {
        padding: 1px;
        display: inline-block;
      }

      .pbScreenKey {
        padding: 1px;
        display: inline-block;
      }
    }

    .msScreenKey {
      position: absolute;
      top: 58px;
      left: 16px;
      font-size: 20px;
    }

    .clock {
      position: absolute;
      bottom: 58px;
      left: 16px;
      font-size: 25px;
    }

    .wifi {
      position: absolute;
      bottom: 30px;
      left: 16px;
      font-size: 12px;
      background-color: #1b1c1d;
      opacity: 0.7;
      color: white;
      padding: 0 2px;

      .wifiIcon {
        position: relative;
        top: 3px;
        color: #21BA45;
        height: 16px;
        width: 16px;
        padding: 0px;
        margin: 0px;
      }
    }

    .msRoomName {
      position: absolute;
      top: 24px;
      left: 16px;
      font-weight: bold;
      font-size: 30px;
    }

    .sideBar {
      background-color: #1b1c1d;
      z-index: 100;
      height: 100%;
      width: 160px;
      right: 0;
      top: 0;
      opacity: 0.7;
      position: absolute;
      padding: 14px;

      .instructions {
        top: 4px;
      }

      .airplay {
        margin: 10px;
        margin-bottom: 0;
      }

      .miracast {
        margin: 10px;
      }

      .calendar {
        height: 69px;
        position: absolute;
        top: 50%;
        width: 118px;
        margin: 10px;
      }
    }

    .singleImage {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

}

@media screen and (max-width: 1200px) {
  .welcomePreview {
    .flashing {
      animation-duration: 1s;
      animation-name: flash;
    }

    @keyframes flash {
      from {
        background-color: rgba($color: #ffffff, $alpha: 0);
      }

      50% {
        background-color: rgba($color: #ffffff, $alpha: 1);
      }

      to {
        background-color: rgba($color: #ffffff, $alpha: 0);
      }
    }

    max-width: 100%;
    max-height: 100%;
    height: 321px;
    width: 572px;
    border: 1px solid grey;
    background-color: lightgrey;
    margin-bottom: 8px;
    position: relative;
    color: white;

    .presenceBar {
      background-color: #1b1c1d;
      z-index: 400;
      bottom: 0;
      width: 100%;
      opacity: 0.7;
      height: 20px;
      position: absolute;

      .pbRoomName {
        padding: 1px;
        display: inline-block;
      }

      .ipAddress {
        padding: 1px;
        display: inline-block;
      }

      .pbScreenKey {
        padding: 1px;
        display: inline-block;
      }
    }

    .msScreenKey {
      position: absolute;
      top: 58px;
      left: 16px;
      font-size: 20px;
    }

    .clock {
      position: absolute;
      bottom: 58px;
      left: 16px;
      font-size: 25px;
    }

    .wifi {
      position: absolute;
      bottom: 30px;
      left: 16px;
      font-size: 12px;
      background-color: #1b1c1d;
      opacity: 0.7;
      color: white;
      padding: 0 2px;

      i {
        color: #21ba45;
      }
    }

    .msRoomName {
      position: absolute;
      top: 24px;
      left: 16px;
      font-weight: bold;
      font-size: 30px;
    }

    .sideBar {
      background-color: #1b1c1d;
      z-index: 100;
      height: 100%;
      width: 160px;
      right: 0;
      top: 0;
      opacity: 0.7;
      position: absolute;
      padding: 14px;

      .instructions {
        top: 4px;
      }

      .airplay {
        margin: 10px;
        margin-bottom: 0;
      }

      .miracast {
        margin: 10px;
      }

      .calendar {
        height: 69px;
        position: absolute;
        top: 50%;
        width: 118px;
        margin: 10px;
      }
    }

    .singleImage {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
