@import 'src/variables.scss';
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zIndexHigh;
  background-color: rgba(0, 0, 0, 0.45);
}

.closeIcon {
  float: right;
  font-size: 16px !important;
  margin-top: -15px !important;
}

.modalContent {
  border-radius: 4px;
  text-align: center;
  width: 450px;
  min-height: 277px;
  font-size: 20px;
  background-color: white;
  margin: 10% auto 0 auto;
  .modal {
    min-height: 200px;
    padding: 32px;
    .modalButton {
      font-size: 16px;
      margin-top: 10%;
      .cancelButton {
        color: #000;
        cursor: pointer;
        margin-right: 15px;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}

.disclaimerText {
  color: #bf1244;
  padding-top: 10px;
  font-size: 14px;
}

.subHeaderText {
  font-size: 14px;
  padding-top: 5px;
}
