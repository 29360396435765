@import './components/DataTableSlim/TableUtils.module';
@import '~react-multi-carousel/lib/styles.css';

html,
body {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.ui.sortable.table thead th.no-hover {
  cursor: default;
}

.modalWrapper {
  background-color: red;
}

.ui.sortable.table thead th.no-hover:hover {
  background-color: #f9fafc;
}

.ui.menu .ui.dropdown .menu>.item.gray {
  background-color: #e6e6e6 !important;
  cursor: default;
  pointer-events: none;
}

/* truncate long names in menus */
.ui.vertical.menu>.ui.item.dropdown {
  display: flex;
}

.ui.vertical.menu>.ui.item.dropdown>.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
}

/* end truncate long names in menus */

.ui.menu .ui.dropdown.menu-dropdown .menu>.selected.item {
  background: 0 0 !important;
}

.ui.menu .ui.dropdown.menu-dropdown .menu>.selected.item:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

/* semantic ui modal */
/* https://github.com/Semantic-Org/Semantic-UI-CSS/issues/47 */
.dimmer.visible.transition {
  display: flex !important;
}

* {
  min-width: 0;
  min-height: 0;
}

.slider-slide {
  min-height: auto !important;
}

.shadow-low {
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.26);
}

.shadow-mid {
  box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 0.26);
}

.shadow-high {
  box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.26);
}

.sbPadding {
  padding: 10px;
}

.ui.table {
  @include emphasizeColumns();

  .exIcon:hover {
    cursor: pointer;
  }

  .greyData {
    color: gray;
    font-style: italic;
  }

  .greyBlur {
    color: transparent;
    text-shadow: 0 0 13px rgba(0, 0, 0, 0.5);
  }
}

// layout styles
.layout-container {
  width: 100%;
  padding: 15px;
  overflow-y: auto;
}

[id^='react-joyride-step-'] {
  button:focus {
    outline: none;
  }
}