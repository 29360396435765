@import 'src/variables.scss';

.drawerOverlay {
  z-index: $zIndexHigher;
  background: rgba(100, 100, 100, 0.2);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

:global(.ReactModal__Overlay) .drawer {
  z-index: $zIndexHighest;
  background: white;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  width: 510px;
  position: fixed;
  top: 0;
  padding: 62px;
  right: -510px;
  transition: 500ms ease-in-out;

  .closeIcon {
    position: absolute;
    z-index: $zIndexHighest;
    top: 35px;
    right: 25px;
    background-color: blue($color: #000000);
  }
}

:global(.ReactModal__Overlay--after-open) .drawer {
  right: 0px;
  transition: 500ms ease-in-out;
}

:global(.ReactModal__Overlay--before-close) .drawer {
  right: -500px;
  transition: 500ms ease-in-out;
}
