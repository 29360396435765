@import 'src/functions';
@import 'src/_mixins';

.view-category-content {
  @include light-scrollbar;
  margin-top: 3rem;
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 1rem;
  }
}

.view-category-title {
  font-size: px-to-rem(16px);
  line-height: px-to-rem(32px);
  height: px-to-rem(32px);
  font-weight: 00;
  text-align: center;
  margin-bottom: 1px solid #000;
}

.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
